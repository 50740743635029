import React from "react";
import {observer} from "mobx-react";
import LoadingComponent from "../../../component/LoadingComponent";
import {Alert, Button, Container, Table} from "react-bootstrap";
import TransactionsList from "../../../component/bank/TransactionsList";
import {useServices} from "../../../providers/Services";
import {useNavigate, useParams} from "react-router";
import {useStore} from "../../../store/RootStore";

const OrganisationBankPage = observer(() => {
    let { orgId } = useParams()
    let { organisationService } = useServices()
    let history = useNavigate()
    let store = useStore()
    let org = store.organisations.getOrCreate(orgId)

    React.useEffect(() => {
        organisationService.listBanks(orgId).then(list => { org.banks.load(list) })
        organisationService.listSpending(orgId).then(list => { org.spending.load(list) })
    }, [])

    if (org.banks.loading) {
        return (<LoadingComponent />)
    } else {
        if (org.banks.list.length > 0) {
            return (
                <Container>
                    <Table>
                        <tbody>
                        {org.banks.list.map(bank => <tr key={bank.bankId}><td className="text-center">
                            <img src={bank.bankLogo} height="50px" /> {bank.bankName}
                        </td></tr>)}
                        </tbody>
                    </Table>

                    <TransactionsList organisation={org} />
                </Container>
            )
        } else {
            return (

                <Alert variant="info" style={{marginTop:"20px"}}>
                    <Alert.Heading>No Banks yet</Alert.Heading>
                    <p>
                        You don't have a bank yet, add one
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant="outline-primary" onClick={() => history(`/org/${orgId}/bank/add`)}>
                            Add Bank
                        </Button>
                    </div>
                </Alert>
            )
        }
    }
})

export default OrganisationBankPage;
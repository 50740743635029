import {useStore} from "../../../store/RootStore";
import LoadingComponent from "../../../component/LoadingComponent";
import {useServices} from "../../../providers/Services";
import React, {useEffect, useState} from "react";
import EditFinancas from "../../../component/financas/EditFinancas";
import {observer} from "mobx-react";
import Moment from "moment";
import {Button, Col, Row} from "react-bootstrap";

const IvaDeclarationRemoteColumn = ({declaration}) => {
    if (declaration.remote) {
        return (
            <>
                <b>{declaration.remote.tipo}</b> ({declaration.remote.situacao}): {declaration.remote.dataRececao}
            </>
        )
    } else {
        return <></>
    }
}

const IvaDeclarationRow = ({declaration}) => {
    let start = Moment(declaration.start)
    let end = Moment(declaration.end)
    return (
        <tr className={Moment().isBetween(start, end) ? "table-info" : ""}>
            <td>{declaration.year} {declaration.label}</td>
            <td>{declaration.start} - {declaration.end}</td>
            <td>{declaration.declarationStart} - {declaration.declarationEnd}</td>
            <td><IvaDeclarationRemoteColumn declaration={declaration}/></td>
        </tr>
    )
}

const IvaDeclarations = ({list}) => {
    return (
        <table className="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Period</th>
                <th>Declaration</th>
                <th>#</th>
            </tr>
            </thead>
            <tbody>

            {list.map(it => <IvaDeclarationRow key={it.id} declaration={it}/>)}
            </tbody>
        </table>
    )
}

const formatNumber = (x) => {
    var parts = parseFloat(x).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

const IrsReciboListItem = ({doc}) => {
    return (
        <tr>
            <td>{doc.numDocumento}</td>
            <td>{doc.dataEmissao}</td>
            <td>{formatNumber(doc.valorBase)}</td>
            <td>{doc.nomeAdquirente}</td>
        </tr>
    )
}

const IrsReciboListStatsRow = ({list}) => {


    return (
        <tr className="table-info">
            <td colSpan="2"><b>Total</b>: {list.length}</td>
            <td>{formatNumber(list.reduce((ac, it) => ac + parseFloat(it.valorBase), 0))}</td>
            <td></td>
        </tr>
    )
}

const IrsReciboList = ({list}) => {
    return (
        <table className="table">
            <thead>
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>Value</th>
                <th>Client</th>
            </tr>
            </thead>
            <tbody>
            {list.map(it => <IrsReciboListItem key={it.numDocumento} doc={it}/>)}
            <IrsReciboListStatsRow list={list}/>
            </tbody>
        </table>
    )
}

const AutoridadeTributariaPage = observer(() => {
    const store = useStore()
    const {personalService} = useServices()
    const [syncing, setSyncing] = useState(false)

    useEffect(() => {
        personalService.financas.profile().then(data => {
            store.personal.financas.load(data)
        })
        personalService.financas.listIva().then(list => {
            store.personal.financas.iva.load(list)
        })
        personalService.financas.listIrsRecibos().then(list => {
            store.personal.financas.irs.recibo.load(list)
        })
    }, [])

    const onSync = () => {
        setSyncing(true)
        personalService.financas.sync().then(data => {
            store.personal.financas.iva.load(data.iva.list)
            store.personal.financas.irs.recibo.load(data.irs.recibos)
            setSyncing(false)
        }).catch(e => {
            setSyncing(false)
        })
    }

    if (store.personal.financas.loading || store.personal.financas.iva.loading || store.personal.financas.irs.recibo.loading) {
        return <LoadingComponent/>
    }

    if (!store.personal.financas.exists) {
        return <EditFinancas/>
    }

    return (
        <div>
            <Row>
                <Col>
                    <div className="d-flex justify-content-end">
                        <Button variant="outline-primary" onClick={() => onSync()} disabled={syncing}>
                            Synchronise Financas
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>IVA</h2>
                    <IvaDeclarations list={store.personal.financas.iva.list}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Recibos Verdes</h2>
                    <IrsReciboList list={store.personal.financas.irs.recibo.list}/>
                </Col>
            </Row>
        </div>
    )
})

export default AutoridadeTributariaPage
import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import BackendClient from "../services/http/BackendClient";
import BankService from "../services/BankService";
import InviteService from "../services/InviteService";
import OrganisationService from "../services/OrganisationService";
import FileService from "../services/FileService";
import PersonalService from "../services/PersonalService";

const ServicesContext = React.createContext(null)

export const useServices = () => React.useContext(ServicesContext);
export const ServicesProvider = ({ children }) => {
    let backendClient = new BackendClient(useAuth0());
    return (
        <ServicesContext.Provider value={{
            bankService: new BankService(backendClient),
            organisationService: new OrganisationService(backendClient),
            inviteService: new InviteService(backendClient),
            fileService: new FileService(backendClient),
            personalService: new PersonalService(backendClient)
        }}>{children}</ServicesContext.Provider>
    );
};
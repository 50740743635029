import React from "react";
import {useAuth0} from "@auth0/auth0-react";

const NotFoundPage = () => {
    return (
        <div className="text-center" style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            <div className="d-flex h-100 p-3 mx-auto flex-column">
                <main role="main" className="inner" style={{
                    padding: "0 1.5rem"
                }}>
                    <h1 className="cover-heading">Page Not Found</h1>
                    <p className="lead">The resource you're trying to access does not exist</p>
                </main>
            </div>
        </div>
    )

}

export default NotFoundPage;
import {action, makeObservable, observable} from "mobx";
import OrganisationBanks from "./OrganisationBanks";
import OrganisationTransactions from "./OrganisationTransactions";
import OrganisationInvites from "./OrganisationInvites";
import OrganisationSpending from "./OrganisationSpending";
import OrganisationClients from "./OrganisationClients";


class Organisation {
    id = ""
    name = ""
    banks = new OrganisationBanks()
    transactions = new OrganisationTransactions()
    invites = new OrganisationInvites()
    spending = new OrganisationSpending()
    clients = new OrganisationClients()

    constructor(data) {
        this.name = data.name
        this.id = data.id
        this.logo = data.logo

        makeObservable(this, {
            banks: observable,
            transactions: observable,
            invites: observable,
            spending: observable,
            clients: observable,
            name: observable,
            id: observable,
            set: action
        })
    }

    set (data) {
        this.name = data.name
        this.id = data.id
        this.logo = data.logo
    }
}

export default Organisation
import {Container} from "react-bootstrap";
import TopBar from "../component/navigation/topbar/TopBar";
import React from "react";


const Layout = ({children}) => {
    return (
        <Container>
            <TopBar/>
            {children}
        </Container>
    )
}

export default Layout
import {action, makeObservable, observable} from "mobx";
import OrganisationTransaction from "./OrganisationTransaction";


class OrganisationTransactions {
    loading = true
    list = []
    map = {}

    constructor() {
        makeObservable(this, {
            loading: observable,
            list: observable,
            map: observable,
            load: action,
            add: action
        })
    }

    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    add (item) {
        if (this.map[item.id]) {
            this.map[item.id].update(item)
        } else {
            let organisationTransaction = new OrganisationTransaction(item);
            this.map[item.id] = organisationTransaction
            this.list.push(organisationTransaction)
        }
    }
}

export default OrganisationTransactions
import PersonalBanks from "./PersonalBanks";
import {makeObservable, observable} from "mobx";
import PersonalTransactions from "./PersonalTransactions";
import PersonalFinancas from "./PersonalFinancas";


class PersonalStore {
    banks = new PersonalBanks()
    transactions = new PersonalTransactions()
    financas = new PersonalFinancas()

    constructor() {
        makeObservable(this, {
            banks: observable,
            transactions: observable,
        })
    }
}

export default PersonalStore
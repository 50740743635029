

export const causedBy = (e, type) => {
    if (e instanceof type) {
        return true
    } else if (e.cause) {
        return causedBy(e.cause, type)
    } else {
        return false
    }
}
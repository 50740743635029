import React, {useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {useParams} from "react-router";
import {useStore} from "../../../store/RootStore";
import {observer} from "mobx-react";
import {useServices} from "../../../providers/Services";
import LoadingComponent from "../../../component/LoadingComponent";

const ListInvites = observer(({org}) => {
    const {orgId} = useParams()
    const {organisationService} = useServices()

    React.useEffect(() => {
        organisationService.listInvites(orgId)
            .then(lst => {
                org.invites.load(lst)
            })
    }, [])

    if (org.invites.loading) {
        return <LoadingComponent/>
    }

    if (org.invites.list.length === 0) {
        return <></>
    }

    return (
        <>
            <h2>Pending Invitations</h2>

            <Table>
                <thead>
                <tr>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {org.invites.list.map(it => <tr key={it.email}>
                    <td>{it.email}</td>
                </tr>)}
                </tbody>
            </Table>

            <hr/>
        </>
    )
})

const OrganisationInvitePage = () => {
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState("")
    const {organisationService} = useServices()
    const {orgId} = useParams()
    const store = useStore()

    let org = store.organisations.getOrCreate(orgId)

    let sendInvite = () => {
        setSending(true)
        organisationService.sendInvitation(orgId, email)
            .then(() => {
                org.invites.add({
                    organisationId: orgId,
                    email: email
                })
                setSending(false)
                setEmail("")
            })
            .catch(e => {
                setSending(false)
            })
    }

    return (
        <Row>
            <Col>

                <ListInvites org={org}/>

                <h2>Invite</h2>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email Address" required value={email}
                                              onChange={e => setEmail(e.target.value)}/>
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                            <Button disabled={sending} variant="primary" onClick={sendInvite}>Invite</Button>
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default OrganisationInvitePage;
import {action, makeObservable, observable} from "mobx";
import OrganisationClientTimesheetsMonth from "./OrganisationClientTimesheetsMonth";

class OrganisationClientTimesheets {
    months = {}

    constructor() {
        makeObservable(this, {
            months: observable,
            getOrCreate: action,
        })
    }

    getOrCreate (date) {
        let id = `${date.getFullYear()}-${date.getMonth()}`;
        if (!this.months[id]) {
            this.months[id] = new OrganisationClientTimesheetsMonth()
        }

        return this.months[id]
    }
}

export default OrganisationClientTimesheets
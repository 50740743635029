import React from "react";
import {Button, Card} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import fileDownload from "js-file-download";
import {useServices} from "../providers/Services";

const DashboardPage = () => {
  const { fileService } = useServices()
  const [searchParams] = useSearchParams();

  let id = searchParams.get("id")
  let fileName = searchParams.get("name")
  let mimeType = searchParams.get("mimeType")

  let downloadFile = () => {
    fileService.publicDownload(id)
        .then(res => {
          fileDownload(res, fileName, mimeType)
        })
  }

  return (
      <div style={{margin: "auto", width: "50%"}} className="mt-5">
        <Card>
          <Card.Body>
            <Card.Title>Download File</Card.Title>
            <dl>
              <dt>Filename</dt>
              <dd>{fileName}</dd>

              <dt>Type</dt>
              <dd>{mimeType}</dd>
            </dl>
          </Card.Body>
          <Card.Footer className="d-grid">
            <Button size="lg" variant="outline-primary" onClick={downloadFile}>Download</Button>
          </Card.Footer>
        </Card>
      </div>
  )
}

export default DashboardPage;

import {action, makeObservable, observable} from "mobx";
import OrganisationClientTimesheets from "./OrganisationClientTimesheets";

const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

class OrganisationClient {
    timesheets = new OrganisationClientTimesheets()

    constructor(data) {
        this.id = data.id
        this.name = data.name
        this.bankIban = data.bankIban
        this.bankSwift = data.bankSwift
        this.from = data.from
        this.address = data.address
        this.rate = data.rate
        this.currency = data.currency

        makeObservable(this, {
            timesheets: observable
        })
    }
}

export default OrganisationClient
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Alert, Button, Container} from "react-bootstrap";
import {useStore} from "../../../store/RootStore";
import LoadingComponent from "../../../component/LoadingComponent";
import BankAddList from "../../../component/bank/add/BankAddList";
import {useParams} from "react-router";
import {useServices} from "../../../providers/Services";
import RequestingLinkComponent from "../../../component/bank/RequestingLinkComponent";


const OrganisationBankAccountEuaExpiredPage = (observer(() => {
    let { orgId, accountId } = useParams()
    let [loaded, setLoaded] = useState(false)
    let [details, setDetails] = useState({})
    let [error, setError] = useState(false)
    let [requestingLink, setRequestingLink] = useState(false)

    let { organisationService, bankService } = useServices()

    useEffect(() => {
        organisationService.accountDetails(orgId, accountId)
            .then(data => {
                setDetails(data)
                setLoaded(true)
            })
            .catch(e => {
                setError(true)
                setLoaded(true)
            })
    }, [])

    if (!loaded) {
        return <LoadingComponent />
    }

    if (error) {
        return <div>Error!</div>
    }

    return (
        <div>


            <Alert variant="info">
                <Alert.Heading>
                    Account in {details.bankName} End User Agreement has Expired
                </Alert.Heading>

                <p>This is part of the open banking specification, we will need to re-issue a new end user agreement</p>

                <Button disabled={requestingLink} onClick={() => {
                    setRequestingLink(true)
                    bankService.dropAndRequestLink(orgId, accountId, details.bankId).then(link => {
                        window.location.href = link
                    })
                }}>Issue New End User Agreement</Button>
            </Alert>
        </div>
    )
}))

export default OrganisationBankAccountEuaExpiredPage;

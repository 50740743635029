import {action, computed, makeObservable, observable, runInAction} from "mobx"
import SingleCall from "./utils/SingleCall";
import OrganisationInvites from "./organisation/OrganisationInvites";
import Organisation from "./organisation/Organisation";


class OrganisationTransaction {
    justification = null

    constructor(data, organisationService) {
        this.organisationService = organisationService;
        this.data = data
        this.justification = data.justification

        makeObservable(this, {
            justification: observable,
            justify: action
        })
    }

    justify (description, file) {
        return this.organisationService.transactionJustify(
            this.data.id,
            description,
            file
        ).then(data => {
            runInAction(() => {
                this.justification = data
            })
            return data
        })
    }

    download () {
        return this.organisationService.transactionJustificationDownload(this.data.id)
    }
}

class OrganisationsStore {
    status = "init";
    map = {};

    constructor() {
        makeObservable(this, {
            map: observable,
            status: observable,
            isLoaded: computed,
            list: computed,
            hasOrganisations: computed,
            load: action,
            addOrganisation: action,
            getOrCreate: action,
        })
    }

    load (list) {
        for (let i=0;i<list.length;i++) {
            let organisation = list[i]
            this.addOrganisation(organisation)
        }
        this.status = "loaded"
    }

    has (id) {
        return !!this.map[id]
    }


    addOrganisation(organisation) {
        if (!this.map[organisation.id]) {
            this.map[organisation.id] = new Organisation(organisation)
        } else {
            this.map[organisation.id].set(organisation)
        }
    }

    get isLoaded() {
        return this.status === "loaded";
    }

    get list() {
        return Object.keys(this.map).sort().map(k => this.map[k])
    }

    get hasOrganisations() {
        return Object.keys(this.map).length > 0;
    }

    getOrCreate(orgId) {
        if (!this.has(orgId)) {
            this.addOrganisation({id: orgId})
        }

        return this.map[orgId]
    }
}

export default OrganisationsStore;
import {Badge, Button, Col, Modal, Row} from "react-bootstrap";
import React, {useState} from "react";


const JustificationModal = ({transaction, handleClose}) => {
    if (!transaction) {
        return <></>
    }

    return (
        <Modal show={transaction} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{transaction.description}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <b>Bank</b>
                    </Col>
                    <Col>
                        {transaction.bank.bankName} <img style={{width: "20px", verticalAlign: "middle"}} src={transaction.bank.bankLogo}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Date</b>
                    </Col>
                    <Col>
                        {transaction.valueDate}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Amount</b>
                    </Col>
                    <Col>
                        <Badge bg={transaction.value > 0 ? "success" : "danger"}>{parseFloat(transaction.value).toFixed(2)} {transaction.transactionAmount.currency}</Badge>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JustificationModal
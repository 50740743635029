import {action, makeObservable, observable} from "mobx";

class OrganisationSpending {
    loading = true;
    list = []

    constructor () {
        makeObservable(this, {
            loading: observable,
            list: observable,
            add: action,
            remove: action,
            load: action
        })
    }

    add (item) {
        if (!this.list.some(it => it.id === item.id)) {
            this.list.push(item)
        }
    }

    remove (item) {
        this.list.replace(this.list.filter(it => it.id !== item.id))
    }

    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    removeAll (list) {
        list.forEach(it => this.remove(it))
    }
}

export default OrganisationSpending
import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import { CSVLink } from "react-csv";
import {useParams} from "react-router";
import {useServices} from "../../providers/Services";
import moment from "moment";

const ShowReport = ({year, data}) => {
  let header = ["Data", "Descricao do Banco", "Entrada", "Saida", "Justificacao"];
  let csvData = [header].concat(data.map(it => [it.date, it.description, it.in, it.out, it.justifications.map(it => it.title).join(", ")]))

  return (
      <div>
        <hr />

        <table className="table table-hover table-striped table-light">
          <thead>
          <tr>
            <td>Data</td>
            <td>Descrição</td>
            <td>Entrada</td>
            <td>Saída</td>
            <td>Justificação</td>
          </tr>
          </thead>
          <tbody>
          {data.map((row, i) => <tr key={i}>
            <td>{row.date}</td>
            <td>{row.description}</td>
            <td>{row.in}</td>
            <td>{row.out}</td>
            <td>
              {row.justifications.length > 1 && <ol>{row.justifications.map((it, ind) => <li key={`${i}_${ind}`}><a target="_blank" href={it.link}>{it.title}</a> [{it.price}]</li>)}</ol>}
              {row.justifications.length <= 1 && row.justifications.map((it, ind) => <div key={`${i}_${ind}`}><a target="_blank" href={it.link}>{it.title}</a></div>)}
            </td>
          </tr>)}
          </tbody>
        </table>

        <CSVLink target="_blank" className="btn btn-primary"
                 data={csvData}
                 filename={`endOfYear${year}.csv`}
        >Download</CSVLink>
      </div>
  )
}

const EndOfYearReport = () => {
  let previousYear = new Date().getFullYear() - 1;
  let {orgId} = useParams()
  const { organisationService } = useServices()
  const [year, setYear] = useState(previousYear)
  const [downloadData, setDownloadData] = useState(null)
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      year: previousYear
    }
  })

  let downloadLinkFor = (transaction) => {
    let baseUrl = `${window.location.protocol}//${window.location.host}`
    if (transaction.justification.file) {
      return `${baseUrl}/download?id=${encodeURI(`justification/${transaction.id}`)}&name=${transaction.justification.manifest.name}&mimeType=${transaction.justification.manifest.mimeType}`
    }
    return null;
  }

  let downloadLinkForBreakdownItem = (transaction, breakdownItem, index) => {
    let baseUrl = `${window.location.protocol}//${window.location.host}`
    if (breakdownItem.attachment) {
      return `${baseUrl}/download?id=${encodeURI(breakdownItem.attachment.filePath)}&name=${breakdownItem.attachment.name}&mimeType=${breakdownItem.attachment.mimeType}`
    }
    return null;
  }

  let makeRequest = (data) => {
    setDownloadData(null)
    setYear(data.year)
    organisationService.listTransactions(orgId).then(list => {
      let filtered = list.filter(it => moment(it.valueDate).year() === parseInt(data.year));
      setDownloadData(filtered.map(t => {
        let justifications = []
        if (t.justification) {
          if (t.justification.type === "breakdown") {
            t.justification.breakdown.forEach((it, ind) => justifications.push({
              title: it.description,
              link: downloadLinkForBreakdownItem(t, it, ind),
              price: it.amount,
            }))
          } else {
            justifications.push({
              title: t.justification.description,
              link: downloadLinkFor(t)
            })
          }
        }

        return {
          date: t.valueDate,
          description: t.description,
          in: t.in ? t.value : "",
          out: !t.in ? t.value : "",
          justifications: justifications
        }
      }))
    })
  }

  return (
      <div>
        <Form onSubmit={handleSubmit(makeRequest, (e) => console.log(e))}>
          <Form.Group className="mb-3" controlId="formOrgName">
            <Form.Label>End of Year Report</Form.Label>
            <Form.Control type="number"
                          placeholder="Enter Year"
                          {...register("year")}/>
            <Form.Control.Feedback type="invalid">{errors.year?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">Generate</Button>
        </Form>
        {downloadData && <ShowReport year={year} data={downloadData} />}
      </div>
  )
}

export default EndOfYearReport;

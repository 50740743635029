import {validStatusCodes} from "./http/utils";

class PersonalTransactionsService {
    constructor(client) {
        this.httpClient = client
    }

    list () {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/personal/transaction/list`, {
                scope: "read:bank"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    sync () {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/personal/transaction/sync`, {
                scope: "write:organisation",
                method: "POST"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
}

class PersonalBankService {
    constructor(client) {
        this.httpClient = client
    }



    acceptAgreement (agreementId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/personal/bank/${agreementId}/accept`, {
                scope: "write:bank",
                method: "PUT"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    list () {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/personal/bank/list`, {
                scope: "read:bank"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
}

class PersonalFinancas {
    constructor(client) {
        this.client = client
    }

    profile () {
        return this.client.execute("/api/personal/financas/profile")
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    listIva () {
        return this.client.execute("/api/personal/financas/iva/declarations/list")
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    listIrsRecibos () {
        return this.client.execute("/api/personal/financas/irs/recibo/list")
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    sync () {
        return this.client.postJson("/api/personal/financas/sync")
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    save (data) {
        return this.client.postJson("/api/personal/financas/profile", data)
            .then(validStatusCodes([200]))
    }
}

class PersonalService {
    constructor(client) {
        this.bank = new PersonalBankService(client)
        this.transaction = new PersonalTransactionsService(client)
        this.financas = new PersonalFinancas(client)
    }
}

export default PersonalService
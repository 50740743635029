import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useStore, withStore} from "../../../store/RootStore";
import {AlreadyExistsError} from "../../../errors/AlreadyExistsError";
import LoadingComponent from "../../LoadingComponent";
import {useServices} from "../../../providers/Services";
import {causedBy} from "../../../utils/Errors";

const AddOrganisation = ({onCreate}) => {
    const [orgName, setOrgName] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [creating, setCreating] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isValid, setIsValid] = useState(true)

    const store = useStore()
    const {organisationService, fileService} = useServices()

    const resetForm = () => {
        setOrgName("")
        setCreating(false)
        setIsValid(true)
        setErrorMessage("")
    }

    const errorForm = (msg) => {
        setCreating(false)
        setIsValid(false)
        setErrorMessage(msg)
    }

    const addOrg = () => {
        if (orgName !== "") {
            setCreating(true)
            organisationService.addOrganisation(orgName, logoUrl)
                .then((data) => {
                    store.organisations.addOrganisation(data)
                    onCreate(data)
                    resetForm()
                })
                .catch(e => {
                    if (causedBy(e, AlreadyExistsError)) {
                        errorForm("Organisation name already exists")
                    } else {
                        errorForm(JSON.stringify(e))
                    }
                })
        } else {
            errorForm("Organisation name required")
        }
    }

    if (creating) {
        return (
            <LoadingComponent/>
        )
    }
    return (
        <Row>
            <Col>
                <Form>
                    <Form.Group className="mb-3" controlId="formOrgName">
                        <Form.Label>Organisation Name</Form.Label>
                        <Form.Control type="text" className={!isValid ? "is-invalid" : ""}
                                      placeholder="Enter Organisation Name" value={orgName}
                                      onChange={(e) => setOrgName(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formOrgName">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Logo URL" value={logoUrl}
                                      onChange={(e) => setLogoUrl(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" onClick={addOrg}>Create</Button>
                </Form>
            </Col>
        </Row>
    );
}

export default AddOrganisation;
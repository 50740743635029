import Env from "../../utils/Env";
import {execute} from "bootstrap/js/src/util";


class BackendClient {
    constructor (auth) {
        this.auth = auth
    }

    execute (url, options) {
        if (!options) options = {}
        return new Promise((resolve, reject) => {
            if (!this.auth) {
                reject("Auth not initiated")
                return
            }

            this.auth.getAccessTokenSilently({
                audience: Env("REACT_APP_AUTH0_AUDIENCE"),
                scope: options.scope || "read:organisation",
            }).then(token => {
                let callOpts = Object.assign({}, options);
                if (!callOpts.headers) callOpts.headers = {}
                callOpts.headers["Authorization"] = `Bearer ${token}`
                callOpts.headers['Accept'] = 'application/json'
                fetch(url, callOpts).then(res => resolve(res)).catch(e => reject({
                    call: "fetch(url, options)",
                    cause: e
                }))
            }).catch(e => reject({
                call: "this.auth.getAccessTokenSilently",
                cause: e
            }))
        })
    }

    postJson (url, body, options) {
        if (!options) options = {}
        return new Promise((resolve, reject) => {
            if (!this.auth) {
                reject("Auth not initiated")
                return
            }

            this.auth.getAccessTokenSilently({
                audience: Env("REACT_APP_AUTH0_AUDIENCE"),
                scope: options.scope || "read:organisation",
            }).then(token => {
                let callOpts = Object.assign({}, options);
                if (!callOpts.headers) callOpts.headers = {}
                callOpts.headers["Authorization"] = `Bearer ${token}`
                callOpts.headers['Accept'] = 'application/json'
                callOpts.headers['Content-Type'] = 'application/json'
                callOpts.body = JSON.stringify(body)
                callOpts.method = "POST"
                fetch(url, callOpts).then(res => resolve(res)).catch(e => reject({
                    call: "fetch(url, options)",
                    cause: e
                }))
            }).catch(e => reject({
                call: "this.auth.getAccessTokenSilently",
                cause: e
            }))
        })
    }
}

export default BackendClient
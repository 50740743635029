import {observer} from "mobx-react";
import {Container, Table} from "react-bootstrap";
import React from "react";

const BankItem = observer(({bank}) => {
    return (
        <tr key={bank.bankId}><td className="text-center">
            <img src={bank.bankLogo} height="50px" alt={bank.bankName} /> {bank.bankName} <input type="checkbox" checked={bank.checked} onChange={() => bank.toggle()}/>
        </td></tr>
    )
})

const BankList = observer(({banks}) => {
    if (banks.length > 0) {
        return (
            <Container>
                <Table>
                    <tbody>
                    {banks.map(bank => <BankItem key={bank.id} bank={bank} />)}
                    </tbody>
                </Table>
            </Container>
        )
    }

    return <></>
})

export default BankList
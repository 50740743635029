class InviteService {
    constructor(client) {
        this.client = client;
    }



    listInvites () {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/invite/list`, {
                scope: "read:bank"
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    accept (orgId) {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/invite/accept`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    organisationId: orgId
                }),
                scope: "write:bank"
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    reject (orgId) {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/invite/reject`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    organisationId: orgId
                }),
                scope: "write:bank"
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
}


export default InviteService;
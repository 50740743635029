class BankService {
    constructor(client) {
        this.client = client;
    }

    list (country) {
        return new Promise((resolve, reject) => {
            this.client.execute("/api/bank/list?country="+encodeURI(country), {
                scope: "read:bank"
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
    requestLink(orgId, bankId) {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/bank/link`, {
                scope: "write:bank",
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    organisationId: orgId,
                    bankId: bankId,
                })
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
    dropAndRequestLink(orgId, accountId, bankId) {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/bank/drop-and-link`, {
                scope: "write:bank",
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    organisationId: orgId,
                    bankId: bankId,
                    accountId: accountId
                })
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
    requestPersonalLink(bankId) {
        return new Promise((resolve, reject) => {
            this.client.execute(`/api/bank/personal/link`, {
                scope: "write:bank",
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    bankId: bankId,
                })
            })
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
}

export default BankService;
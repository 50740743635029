import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../store/RootStore";
import {useServices} from "../../providers/Services";
import {Button, Table} from "react-bootstrap";

const InviteRow = ({invite, onAccept, onReject}) => {
    const [disabled, setDisabled] = useState(false)

    return (
        <tr>
            <td>{invite.organisationName}</td>
            <td>
                <Button disabled={disabled} variant="success" onClick={() => {
                    setDisabled(true)
                    onAccept(invite)
                }}>Accept</Button>
                <Button disabled={disabled} variant="danger" onClick={() => {
                    setDisabled(true)
                    onReject(invite)
                }}>Reject</Button>
            </td>
        </tr>
    )
}

const ListInvites = observer(() => {
    const store = useStore()
    const { inviteService } = useServices()

    const onAccept = (invite) => {
        inviteService.accept(invite.organisationId).then((org) => {
            store.invites.remove(invite)
            store.organisations.addOrganisation(org)
        })
    }
    const onReject = (invite) => {
        inviteService.reject(invite.organisationId).then(() => {
            store.invites.remove(invite)
        })
    }

    React.useEffect(() => {
        if (store.invites.loading) {
            inviteService.listInvites().then(data => store.invites.load(data))
        }
    }, [])

    let i=0

    if (store.invites.loading) {
        return <></>
    } else {
        if (store.invites.list.length === 0) {
            return <></>
        } else {

            return (
                <>
                    <h2>Pending Invites</h2>

                    <Table>
                        <thead>
                        <tr>
                            <th>Organisation</th>
                            <th>Option</th>
                        </tr>
                        </thead>
                        <tbody>
                        {store.invites.list.map(it => <InviteRow key={i++} invite={it} onAccept={onAccept} onReject={onReject} />)}
                        </tbody>
                    </Table>

                    <hr />
                </>
            )
        }
    }

})

export default ListInvites
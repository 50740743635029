import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import LoadingComponent from "../../../component/LoadingComponent";
import {useServices} from "../../../providers/Services";


const PersonalBankAddCallbackPage = () => {
    const [searchParams] = useSearchParams();
    let agreementId = searchParams.get("ref");
    let history = useNavigate()

    let { personalService } = useServices()

    useEffect(() => {
        personalService.bank.acceptAgreement(agreementId).then(() => {
            history(`/personal/bank`)
        })
    })

    return (
        <div className="text-center" style={{marginTop: "50px"}}>
            <div className="d-flex h-100 p-3 mx-auto flex-column">
                <main role="main" className="inner" style={{
                    padding: "0 1.5rem"
                }}>
                    <h1 className="cover-heading">We are setting up your bank account</h1>
                    <p className="lead">Please wait while we work on this</p>

                    <LoadingComponent />
                </main>
            </div>
        </div>
    )
}

export default PersonalBankAddCallbackPage;
import {action, makeObservable, observable} from "mobx";

class OrganisationTransaction {
    constructor(data) {
        this.id = data.id
        this.in = data.in
        this.value = data.value
        this.valueDate = data.valueDate
        this.justification = data.justification
        this.description = data.description

        makeObservable(this, {
            justification: observable,
            setJustification: action,
            update: action
        })
    }

    setJustification (j) {
        this.justification = j
    }

    update (data) {
        this.id = data.id
        this.in = data.in
        this.value = data.value
        this.valueDate = data.valueDate
        this.justification = data.justification
        this.description = data.description
    }
}

export default OrganisationTransaction
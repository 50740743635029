import {Button, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import LoadingSpin from "react-loading-spin";
import {useServices} from "../../../../providers/Services";

const BreakdownItem = ({breakdownIndex, breakdownItem, onRemove}) => {
    return (
        <tr>
            <td>{breakdownItem.description}</td>
            <td>{breakdownItem.amount}</td>
            <td>
                {breakdownItem.selectedFile ? breakdownItem.selectedFile.name : "No file"}
            </td>
            <td><Button variant="outline-danger" className="btn-sm" onClick={() => onRemove(breakdownIndex)}><FontAwesomeIcon icon={faCircleXmark} /></Button></td>
        </tr>
    )
}



const totalBreakdown = (breakdown) => {
    return breakdown.map(it => it.amount).reduce((ac, n) => ac + parseFloat(n), 0).toFixed(2)
}
const BreakdownJustification = ({transaction, onJustify}) => {
    const [breakdown, setBreakdown] = useState(transaction.breakdown || [])
    const [justifyDisabled, setJustifyDisabled] = useState(true)

    const [selectedFile, setSelectedFile] = useState(null)
    const [rowFileElement, setRowFileElement] = useState(null)
    const [addLoading, setAddLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [descriptionValid, setDescriptionValid] = useState(true)
    const [amount, setAmount] = useState("")
    const [amountValid, setAmountValid] = useState(true)

    const { fileService, organisationService } = useServices()

    const isValidForm = () => {
        let validations = [
            () => {
                if (!description) {
                    setDescriptionValid(false)
                    return false
                } else {
                    setDescriptionValid(true)
                    return true
                }
            },
            () => {
                if (!amount || parseFloat(amount) <= 0) {
                    setAmountValid(false)
                    return false
                } else {
                    setAmountValid(true)
                    return true
                }
            }
        ]

        return validations
            .map(it => it())
            .every(it => it)
    }

    const resetForm = () => {
        setDescriptionValid(true)
        setDescription("")
        setAmount("")
        setAmountValid(true)
        setSelectedFile(null)

        if (rowFileElement) {
            rowFileElement.target.value = null
        }
    }

    const removeBreakdownItem = (index) => {
        let list = [...breakdown]
        list.splice(index, 1)
        setBreakdown(list)
        if (totalBreakdown(list) === parseFloat(transaction.value).toFixed(2)) {
            setJustifyDisabled(false)
        } else {
            setJustifyDisabled(true)
        }
    }

    const addBreakdownItem = () => {
        setAddLoading(true)
        if (isValidForm()) {
            let list = [...breakdown, {
                description: description,
                amount: parseFloat(amount).toFixed(2),
                selectedFile: selectedFile
            }]
            resetForm()
            setBreakdown(list)
            if (totalBreakdown(list) === parseFloat(transaction.value).toFixed(2)) {
                setJustifyDisabled(false)
            } else {
                setJustifyDisabled(true)
            }
        }
        setAddLoading(false)
    }

    const addBreakdown = () => {
        let i = 0;
        Promise.all(breakdown.map(it => new Promise((resolve, reject) => {
            let n = i++
            if (it.selectedFile) {
                let filePath = `justification/${transaction.id}|${n}`;
                return fileService.uploadFile(filePath, it.selectedFile)
                    .then(manifest => {
                        manifest.filePath = filePath
                        resolve({
                            description: it.description,
                            amount: it.amount,
                            attachment: manifest
                        })
                    })
                    .catch(e => reject(e))
            } else {
                resolve({
                    description: it.description,
                    amount: it.amount
                })
            }
        })))
            .then(list => {
                organisationService.transactionJustifyBreakdown(transaction.id, list, selectedFile)
                    .then(() => {
                        onJustify()
                    })
            })
            .catch(e => console.log(e))
    }

    let i = 0;

    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>File</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {breakdown.map(it => <BreakdownItem breakdownIndex={i} key={i++} breakdownItem={it} onRemove={removeBreakdownItem}/>)}
                <tr className="table-info">
                    <td><b>Total</b></td>
                    <td>{totalBreakdown(breakdown)}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className="table-secondary">
                    <td>
                        <Form.Control
                            className={descriptionValid ? "" : "is-invalid"}
                            placeholder="Description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </td>
                    <td>
                        <Form.Control
                            type="number"
                            pattern="[0-9]+(\.[0-9]+)?"
                            step="0.01"
                            className={amountValid ? "" : "is-invalid"}
                            placeholder="Amount"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />
                        <Form.Control.Feedback tooltip type="invalid">Description required</Form.Control.Feedback>
                    </td>
                    <td>
                        <Form.Control
                            className="mb-2"
                            type="file"
                            onChange={e => {
                                setSelectedFile(e.target.files[0]);
                                setRowFileElement(e)
                            }}
                        />
                        <Form.Control.Feedback tooltip type="invalid">Amount greater than 0 required</Form.Control.Feedback>
                    </td>
                    <td>
                        <Button onClick={addBreakdownItem} disabled={addLoading} variant="outline-primary" className="mb-2">
                            {!addLoading && <FontAwesomeIcon icon={faCirclePlus} />}
                            {addLoading && <LoadingSpin size="10px" />}
                        </Button>
                    </td>
                </tr>
                </tbody>
            </Table>
            <Row>
                <Col>
                    <Form.Control
                        disabled={justifyDisabled}
                        type="file"
                        onChange={e => setSelectedFile(e.target.files[0])}
                    />
                </Col>
                <Col>
                    <Button style={{width: "100%"}} className="btn-block" disabled={justifyDisabled} onClick={addBreakdown}>Justify</Button>
                </Col>
            </Row>
        </>
    )
}

export default BreakdownJustification
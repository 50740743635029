import React from "react";
import {Card} from "react-bootstrap";
import {withRouter} from "../../../utils/Router";
import {useNavigate} from "react-router";

const Organisation = ({org}) => {
    const history = useNavigate()

    const logo = () => {
        if (!org.logo) {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/542px-Unknown_person.jpg"
        } else {
            return org.logo
        }
    }

    return (
        <Card style={{ margin: '20px', cursor: "pointer" }} onClick={() => { history(`/org/${org.id}`) }}>
            <Card.Body>
                <div className="text-center" style={{marginBottom: "30px"}}>
                    <img src={logo()} style={{width: "200px", height: "200px"}} />
                </div>
                <Card.Title>
                    <div className="text-center">{org.name}</div>
                </Card.Title>
            </Card.Body>
        </Card>
    );
}

export default Organisation;
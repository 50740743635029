import React from "react";
import {observer} from "mobx-react";
import {Table} from "react-bootstrap";
import {useStore, withStore} from "../../../store/RootStore";
import {useServices} from "../../../providers/Services";
import LoadingComponent from "../../LoadingComponent";

const BankRow = ({data, onClick}) => {
    return (
        <tr key={data.id} style={{cursor: "pointer"}} onClick={onClick}>
            <td><img src={data.logo} height="50px"  alt={data.name}/> {data.name} ({data.bic})</td>
        </tr>
    )
}

let bankLoad = null

const BankAddList = (observer(({banks, onClick}) => {
    let store = useStore()
    let { bankService } = useServices()

    React.useEffect(() => {
        if (bankLoad === null) {
            bankLoad = bankService.list("pt").then(list => {
                store.banks.load(list)
            })
        }
    }, [])

    if (store.banks.loading) {
        return (
            <LoadingComponent />
        );
    }

    return (
        <Table bordered hover>
            <thead><tr>
                <th>Bank <small style={{fontWeight: 100}}>Choose the one you want</small></th>
            </tr></thead>
            <tbody>
            {banks.map(item => <BankRow key={item.id} data={item} onClick={() => onClick(item.id)} />)}
            </tbody>
        </Table>
    )
}))

export default BankAddList;
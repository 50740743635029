import {action, makeObservable, observable} from "mobx";
import BankService from "../services/BankService";


class BanksStore {
    list = [];
    loading = true;

    constructor() {
        makeObservable(this, {
            list: observable,
            loading: observable,
            add: action,
            load: action
        })
    }

    load(list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    add (item) {
        if (!this.list.some(elem => elem.id === item.id)) {
            this.list.push(item)
        }
    }
}

export default BanksStore;
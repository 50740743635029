

class FileService {
    constructor(client) {
        this.client = client
    }

    uploadFile (filePath, file) {
        return new Promise((resolve, reject) => {
            let data = new FormData()
            data.append("file", file)
            this.client.execute(`/api/file/upload?path=${encodeURI(filePath)}`, {
                scope: "write:organisation",
                method: "POST",
                body: data
            })
                .then(res => res.json())
                .then(res => resolve(res))
                .catch(e => {
                    reject(e)
                })
        })
    }

    download (filepath) {
        return this.client.execute(`/api/file/download?path=${encodeURI(filepath)}`, {
            scope: "read:organisation"
        })
    }

    publicDownload (filepath) {
        return fetch(`/api/public/file/download?path=${encodeURI(filepath)}`)
            .then(res => res.blob())
    }
}

export default FileService

import {action, makeObservable, observable} from "mobx";

class InvitesStore {
    list = []
    loading = true

    constructor () {

        makeObservable(this, {
            loading: observable,
            list: observable,
            load: action,
            remove: action
        })
    }

    load (list) {
        this.list.replace(list)
        this.loading = false
    }

    remove (invite) {
        this.list = this.list.filter(item => item.organisationId !== invite.organisationId);
    }
}

export default InvitesStore
import React from "react";
import Organisation from "./Organisation";
import {observer} from "mobx-react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useStore} from "../../../store/RootStore";
import LoadingSpin from "react-loading-spin";
import LoadingComponent from "../../LoadingComponent";
import {useServices} from "../../../providers/Services";

const splitArrayIntoChunksOfLen = (arr, len) =>{
    let chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}

const ListOrganisationsView = ({organisations}) => {
    return (
        <div>
            {splitArrayIntoChunksOfLen(organisations,3).map((batch, i) =>
                <Row key={"row"+i}>
                    {batch.map((org) =>
                        <Col md={4} key={org.id}>
                            <Organisation org={org} />
                        </Col>
                    )}
                </Row>
            )}
        </div>
    )
}

const ListOrganisations = observer(() => {
    const history = useNavigate()
    const store = useStore()
    const { organisationService } = useServices()

    React.useEffect(() => {
        organisationService.loadOrganisations().then(list => {
            store.organisations.load(list)
        })
    }, [])

    if (!store.organisations.isLoaded) {
        return (
            <LoadingComponent />
        )
    } else {
        if (store.organisations.hasOrganisations) {
            return (
                <div>
                    <ListOrganisationsView organisations={store.organisations.list} />
                    <hr />
                    <Row>
                        <div className="d-flex justify-content-end">
                            <Button variant="outline-primary" onClick={() => history("/org/add")}>
                                Create Organisation
                            </Button>
                        </div>
                    </Row>
                </div>
            );
        } else {
            return (
                <Alert variant="info" style={{marginTop:"20px"}}>
                    <Alert.Heading>No Organisations yet</Alert.Heading>
                    <p>
                        You don't have an organisation yet, add one
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant="outline-primary" onClick={() => history("/org/add")}>
                            Create Organisation
                        </Button>
                    </div>
                </Alert>
            );
        }
    }
})

export default ListOrganisations;
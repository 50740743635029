
class InvalidStatusError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response
    }
}

export const validStatusCodes = (statusCodes) => {
    return (res) => {
        if (!statusCodes.some(it => res.status === it)) {
            throw Error(`Status code ${res.status} unexpected, expecting one of ${JSON.stringify(statusCodes)}`)
        }
        return res
    }
}
import React from "react";
import LoadingSpin from "react-loading-spin";

const LoadingPage = () => {
    return (
        <div className="text-center" style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            <LoadingSpin />
        </div>
    )

}

export default LoadingPage;
import React, {useState} from "react";
import { CSVLink } from "react-csv";
import {useNavigate, useParams} from "react-router";
import LoadingComponent from "../LoadingComponent";
import {observer} from "mobx-react";
import {Alert, Badge, Button, Col, Collapse, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faArrowsDownToLine, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import fileDownload from 'js-file-download'
import {useServices} from "../../providers/Services";
import TransactionJustification from "./transaction/justification/TransactionJustification";

const ShowJustification = observer(({transaction, onToggle}) => {
    const { organisationService } = useServices()
    let justification = transaction.justification

    const handleDownload = () => {
        organisationService.transactionJustificationDownload(transaction.id)
            .then(res => res.blob())
            .then(res => {
                fileDownload(res, justification.manifest.name, justification.manifest.mimeType)
            })
    }

    if (!justification) {
        return (<div className="text-center"><FontAwesomeIcon icon={faPenToSquare} style={{cursor: "pointer"}} onClick={onToggle}/></div>);
    } else {
        if (justification.type === "breakdown") {
            if (!justification.manifest) {
                return (
                    <Row>
                        <Col><FontAwesomeIcon icon={faPenToSquare} style={{cursor: "pointer"}} onClick={onToggle}/> {justification.description}</Col>
                    </Row>
                )
            }
            return (
                <Row>
                    <Col><FontAwesomeIcon style={{cursor: "pointer"}} icon={faPenToSquare} onClick={onToggle}/> <a className="link-primary" style={{cursor: "pointer"}}  onClick={handleDownload}>Download</a> | <FontAwesomeIcon icon={faArrowsDownToLine} /> </Col>
                </Row>
            );
        } else {
            if (!justification.manifest) {
                return (
                    <Row>
                        <Col><FontAwesomeIcon icon={faPenToSquare} style={{cursor: "pointer"}} onClick={onToggle}/> {justification.description}</Col>
                    </Row>
                )
            }
            return (
                <Row>
                    <Col><FontAwesomeIcon style={{cursor: "pointer"}} icon={faPenToSquare} onClick={onToggle}/> <a className="link-primary" style={{cursor: "pointer"}}  onClick={handleDownload}>Download</a> | {justification.description}</Col>
                </Row>
            );
        }
    }
})


const ShowBreakdownJustification = observer(({breakdownItem}) => {
    const { fileService } = useServices()

    const handleDownload = () => {
        fileService.download(breakdownItem.attachment.filePath)
            .then(res => res.blob())
            .then(res => {
                fileDownload(res, breakdownItem.attachment.name, breakdownItem.attachment.mimeType)
            })
    }

    if (!breakdownItem.attachment) {
        return (
            <Row>
                <Col>{breakdownItem.description}</Col>
            </Row>
        )
    }
    return (
        <Row>
            <Col><a className="link-primary" style={{cursor: "pointer"}}  onClick={handleDownload}>Download</a> | {breakdownItem.description} </Col>
        </Row>
    );
})

const BreakdownRow = ({isIn, breakdownItem}) => {
    return (
        <tr className="table-secondary">
            <td style={{padding: 0, paddingLeft: "8px", textAlign: "right"}}><FontAwesomeIcon icon={faArrowRight} /></td>
            <td style={{padding: 0, paddingLeft: "8px"}}><small>{breakdownItem.description}</small></td>
            <td style={{padding: 0, paddingLeft: "8px"}}>{isIn && <small>+ <Badge bg="success">{parseFloat(breakdownItem.amount).toFixed(2)}</Badge></small>}</td>
            <td style={{padding: 0, paddingLeft: "8px"}}>{!isIn && <small>+ <Badge bg="danger">{parseFloat(breakdownItem.amount).toFixed(2)}</Badge></small>}</td>
            <td style={{padding: 0, paddingLeft: "8px"}}>
                <small><ShowBreakdownJustification breakdownItem={breakdownItem} /></small>
            </td>
        </tr>
    )
}

const ShowBreakdown = ({transaction}) => {
    let i = 0;
    return (
        <>
            {transaction.justification.breakdown.map(it => <BreakdownRow isIn={transaction.in} breakdownItem={it} key={i++} />)}
        </>
    )
}

const TransactionRow = observer(({transaction}) => {
    const [showEdit, setShowEdit] = useState(false)

    return (
        <>
            <tr className={transaction.justification ? "table-success" : "table-danger"}>
                <td>{transaction.valueDate}</td>
                <td>{transaction.description}</td>
                <td>{transaction.in ? (
                    <Badge bg="success">{parseFloat(transaction.value).toFixed(2)}</Badge>) : ""}</td>
                <td>{!transaction.in ? (
                    <Badge bg="danger">{parseFloat(transaction.value).toFixed(2)}</Badge>) : ""}</td>
                <td>
                    <ShowJustification transaction={transaction} onToggle={() => setShowEdit(!showEdit)}/>
                </td>
            </tr>
            {transaction.justification && transaction.justification.type === "breakdown" && <ShowBreakdown transaction={transaction} />}
            <Collapse in={showEdit}>
                <tr>
                    <td colSpan="5">
                        <TransactionJustification transaction={transaction} onJustify={() => setShowEdit(false)} />
                    </td>
                </tr>
            </Collapse>
        </>
    )
})

const ExportCSV = ({org, transactions}) => {
    let data = [["Data", "Descricao do Banco", "Entrada", "Saida", "Justificacao"]]
    transactions.forEach(t => {
        let justification = ""
        if (t.justification) {
            if (t.justification.type === "breakdown") {
                justification = t.justification.breakdown.map(it => it.description).join(" + ")
            } else {
                justification = t.justification.description
            }
        }
        data.push([t.valueDate, t.description, t.in ? t.value : "",  !t.in ? t.value : "", justification])
    })
    return (
        <CSVLink data={data} target="_blank" className="btn btn-primary" style={{marginLeft: "1em"}} filename={`bank-export-${org.id}.csv`}>Export in CSV</CSVLink>
    )
}

const TransactionsList = observer(({organisation}) => {
    let {orgId} = useParams()
    const { organisationService } = useServices()
    const [syncing, setSyncing] = useState(false)

    let navigate = useNavigate();

    const syncTransactions = () => {
        setSyncing(true)
        organisationService.syncTransactions(orgId).then(list => {
            setSyncing(false)
            organisation.transactions.load(list)
        }).catch(e => {
            if (e.errorType === "ACCOUNT_SUSPENDED") {
                navigate(`/org/${orgId}/bank/account/${e.accountId}/suspended`)
            } else if (e.errorType === "EUA_EXPIRED") {
                navigate(`/org/${orgId}/bank/account/${e.accountId}/eua-expired`)
            } else {
                setSyncing(false)
            }
        })
    }

    React.useEffect(() => {
        organisationService.listTransactions(orgId).then(list => {
            organisation.transactions.load(list)
        })
    }, [])

    if (organisation.transactions.loading) {
        return <LoadingComponent/>
    }

    if (organisation.transactions.list.length === 0) {
        return (
            <Alert variant="info" style={{marginTop: "20px"}}>
                <Alert.Heading>No Transactions yet</Alert.Heading>
                <p>
                    You don't have transactions yet, sync up your accounts
                </p>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" onClick={() => syncTransactions()} disabled={syncing}>
                        {syncing && "Synchronising Accounts..."}
                        {!syncing && "Synchronise Accounts"}
                    </Button>
                </div>
            </Alert>
        )
    } else {
        let total = organisation.transactions.list.reduce((ac, it) => ac + (it.value*(it.in ? 1:-1)), 0).toFixed(2)
        let totalIn = organisation.transactions.list.reduce((ac, it) => {
            if (it.in) {
                return ac + it.value
            }
            return ac
        }, 0).toFixed(2)
        let totalOut = organisation.transactions.list.reduce((ac, it) => {
            if (!it.in) {
                return ac + it.value
            }
            return ac
        }, 0).toFixed(2)

        return (
            <div>
                <Row>
                    <Col><h2>Transactions</h2></Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Button variant="outline-primary" onClick={() => syncTransactions()} disabled={syncing}>
                                {syncing && "Synchronising Accounts..."}
                                {!syncing && "Synchronise Accounts"}
                            </Button>
                            <ExportCSV transactions={organisation.transactions.list} org={organisation} />
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount In</th>
                        <th>Amount Out</th>
                        <th>Justification</th>
                    </tr>
                    </thead>
                    <tbody>
                    {organisation.transactions.list.map(it => <TransactionRow key={it.id} transaction={it}/>)}
                    <tr className="table-info">
                        <td colSpan={2}><b>Total:</b> {organisation.transactions.list.length}</td>
                        <td><Badge bg="success">{totalIn}</Badge></td>
                        <td><Badge bg="danger">{totalOut}</Badge></td>
                        <td><b>Current: </b><Badge bg={total > 0 ? "success" : "danger"}>{total}</Badge></td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
})

export default TransactionsList

import LoadingComponent from "../LoadingComponent";
import React from "react";


const RequestingLinkComponent = () => {
    return (
        <div className="text-center" style={{marginTop: "50px"}}>
            <div className="d-flex h-100 p-3 mx-auto flex-column">
                <main role="main" className="inner" style={{
                    padding: "0 1.5rem"
                }}>
                    <h1 className="cover-heading">You will be redirected</h1>
                    <p className="lead">To accept the bank agreement terms</p>
                    <div className="lead">
                        <LoadingComponent />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default RequestingLinkComponent
import {action, makeObservable, observable} from "mobx";

class PersonalTransactions {
    list = []
    loading = true

    constructor() {
        makeObservable(this, {
            list: observable,
            loading: observable,
            add: action,
            load: action
        })
    }

    add (transaction) {
        let listFiltered = this.list.filter(it => it.id === transaction.id);
        if (listFiltered.length > 0) {
            // do nothing!
        } else {
            this.list.push(transaction)
        }
    }

    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

}

export default PersonalTransactions
import {action, makeObservable, observable} from "mobx";

class OrganisationBanks {
    list = []
    loading = true

    constructor() {
        makeObservable(this, {
            list: observable,
            loading: observable,
            load: action
        })
    }


    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    add (item) {
        if (!this.list.some(it => it.id === item.id)) {
            this.list.push(item)
        }
    }
}

export default OrganisationBanks
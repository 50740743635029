import {Button, Col, Form, Row} from "react-bootstrap";
import LoadingSpin from "react-loading-spin";
import React, {useState} from "react";
import {useServices} from "../../../../providers/Services";

const SimpleTransactionJustification = ({transaction, onJustify}) => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [description, setDescription] = useState(transaction.justification ? transaction.justification.description : "")
    const [updating, setUpdating] = useState(false)
    const { organisationService } = useServices()


    const justify = () => {
        setUpdating(true)

        organisationService.transactionJustify(transaction.id, description, selectedFile).then(justification => {
            transaction.setJustification(justification)
            onJustify()
            setUpdating(false)
        }).catch(e => {
            setUpdating(false)
        })
    }

    return (
        <Form>
            <Row className="align-items-center">
                <Col>
                    <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                        Name
                    </Form.Label>
                    <Form.Control
                        className="mb-2"
                        placeholder="Description"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </Col>
                <Col xs="auto">
                    <Form.Control
                        className="mb-2"
                        type="file"
                        placeholder="Description"
                        onChange={e => setSelectedFile(e.target.files[0])}
                    />
                </Col>
                <Col xs="auto">
                    <Button className="mb-2" disabled={updating} onClick={justify}>Justify {updating && <LoadingSpin size="10px" />}</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default SimpleTransactionJustification
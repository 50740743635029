import {Card, Container, Tab, Tabs} from "react-bootstrap";
import React from "react";
import SimpleTransactionJustification from "./SimpleTransactionsJustification";
import BreakdownJustification from "./BreakdownJustification";
import SpendingJustification from "./SpendingJustification";

const TabBody = ({children}) => {
    return (
        <Container>{children}</Container>
    )
}

const TransactionJustification = ({transaction, onJustify}) => {
    return (
        <Container>
            <Tabs
                defaultActiveKey="simple"
                className="mb-3 justification-tab"
                fill
            >
                <Tab eventKey="simple" title="Simple" className="justification-tab-content">
                    <TabBody>
                        <SimpleTransactionJustification transaction={transaction} onJustify={onJustify} />
                    </TabBody>
                </Tab>
                <Tab eventKey="breakdown" title="Breakdown" className="justification-tab-content">
                    <TabBody>
                        <BreakdownJustification transaction={transaction} onJustify={onJustify} />
                    </TabBody>
                </Tab>
                <Tab eventKey="spending" title="Spending" className="justification-tab-content">
                    <TabBody>
                        <SpendingJustification transaction={transaction} onJustify={onJustify} />
                    </TabBody>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default TransactionJustification
import React from "react";
import BanksStore from "./BanksStore";
import InvitesStore from "./InvitesStore";
import OrganisationsStore from "./OrganisationsStore";
import PersonalStore from "./personal/PersonalStore";

class RootStoreImpl {
    constructor() {
        this.organisations = new OrganisationsStore();
        this.banks = new BanksStore();
        this.invites = new InvitesStore()
        this.personal = new PersonalStore()
    }
}

const RootContext = React.createContext(null)
const RootStore = new RootStoreImpl()

export const RootStoreProvider = ({ children }) => {
    return (
        <RootContext.Provider value={RootStore}>{children}</RootContext.Provider>
    );
};

export const useStore = () => React.useContext(RootContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
    return <Component {...props} store={useStore()} />;
};
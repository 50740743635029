import {useEffect, useState} from "react";
import Moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useServices} from "../../../providers/Services";
import {useParams} from "react-router";
import {useStore} from "../../../store/RootStore";
import LoadingComponent from "../../../component/LoadingComponent";
import {Button, ButtonGroup} from "react-bootstrap";
import {observer} from "mobx-react";

const daysFor = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

const isWeekDay = date => {
    return [1,2,3,4,5].some(d => d === date.getDay())
}

const extractInitialChoice = (client, day) => {
    let info = client.timesheets.getOrCreate(day).get(Moment(day).format("YYYY-MM-DD"))
    if (info) {
        if (info.reported === "fullDay") return 1;
        if (info.reported === "halfDay") return 2;
        if (info.reported === "offDay") return 3;
    }
    return 0
}

const CalendarClientColumn = observer(({client, day}) => {
    const [choice, setChoice] = useState(extractInitialChoice(client, day))
    const { organisationService } = useServices()
    const { orgId } = useParams()

    const setDayInfo = (option) => {
        setChoice(option)
        organisationService.clients.timesheets.add(orgId, client.id, day.getFullYear(), day.getMonth(), day.getDate(), {
            reported: (option === 1) ? "fullDay" : ((option === 2) ? "halfDay" : "offDay")
        }).then(data => {
            client.timesheets.getOrCreate(day).add(data)
        })
    }

    return (
        <td className="text-center">
            <ButtonGroup>
                <Button variant={choice === 1 ? "primary" : "outline-primary"} size="sm" onClick={() => setDayInfo(1)}>FD</Button>
                <Button variant={choice === 2 ? "primary" : "outline-primary"} size="sm" onClick={() => setDayInfo(2)}>HD</Button>
                <Button variant={choice === 3 ? "primary" : "outline-primary"} size="sm" onClick={() => setDayInfo(3)}>OD</Button>
            </ButtonGroup>
        </td>
    )
})

const CalendarDay = ({date, clients}) => {
    if (!isWeekDay(date)) {
        return (
            <tr className={isWeekDay(date) ? "" : "table-secondary"}>
                <td style={{width: "1%"}}>{Moment(date).format("dddd")}</td>
                <td style={{width: "1%"}}>{Moment(date).format("DD")}</td>
                {clients.map(it => <td key={it.id} />)}
            </tr>
        )
    }
    return (
        <tr className={isWeekDay(date) ? "" : "table-secondary"}>
            <td style={{width: "1%"}}>{Moment(date).format("dddd")}</td>
            <td style={{width: "1%"}}>{Moment(date).format("DD")}</td>
            {clients.map(it => <CalendarClientColumn key={it.id} client={it} day={date} />)}
        </tr>
    )
}

const isSameMonth = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth()
}

const Calendar = observer(({clients}) => {
    const initDate = new Date()
    const [baseDate, setBaseDate] = useState(initDate)
    const { orgId } = useParams()
    const { organisationService } = useServices()

    useEffect(() => {
        Promise.all(clients.map(it => organisationService.clients.timesheets.list(orgId, it.id, baseDate.getFullYear(), baseDate.getMonth())))
            .then(list => list.forEach((timesheetList, index) => {
                clients[index].timesheets.getOrCreate(baseDate).load(timesheetList)
            }))
    }, [baseDate])

    if (clients.some(it => it.timesheets.getOrCreate(baseDate).loading)) {
        return <LoadingComponent />
    }

    let days = daysFor(baseDate.getMonth(), baseDate.getFullYear())

    let sClients = clients.reduce(function (acc, item) {
        acc[item.id] = {
            rate: item.rate,
            days: 0.0,
            timesheets: item.timesheets
        }
        return acc;
    }, {})

    days.forEach(day => {
        clients.forEach(client => {
            let timesheetVal = client.timesheets.getOrCreate(day).get(Moment(day).format("YYYY-MM-DD"))
            if (timesheetVal) {
                if (timesheetVal.reported === "fullDay") {
                    sClients[client.id].days += 1.0
                } else if (timesheetVal.reported === "halfDay") {
                    sClients[client.id].days += 0.5
                }
            }
        })
    })

    return (
        <>
            <h3>
                <a
                    className="link-primary"
                    onClick={() => setBaseDate(Moment(baseDate).subtract(1, 'months').toDate())}
                    style={{marginRight: "20px", cursor: "pointer"}}><FontAwesomeIcon icon={faArrowLeft} /></a>

                <span style={{textDecoration: (isSameMonth(initDate, baseDate) ? "underline" : "")}}>{Moment(baseDate).format("MMMM YYYY")}</span>

                <a
                    className="link-primary"
                    onClick={() => setBaseDate(Moment(baseDate).add(1, 'months').toDate())}
                    style={{marginLeft: "20px", cursor: "pointer"}}><FontAwesomeIcon icon={faArrowRight} /></a>
            </h3>

            <table className="table table-hover">
                <thead>
                <tr>
                    <th colSpan="2">Day</th>
                    {clients.map(it => <th className="text-center" key={it.id}>{it.name}</th>)}
                </tr>
                </thead>
                <tbody>
                {days.map(it => <CalendarDay key={Moment(it).format("YYYYMMDD")} date={it} clients={clients} />)}
                <tr>
                    <td colSpan={2}></td>
                    {Object.keys(sClients).map(k => <td id={k} className="text-center">
                        <b>Total</b>: {sClients[k].days} | <b>$</b>: {sClients[k].days * sClients[k].rate}
                    </td>)}
                </tr>
                </tbody>
            </table>
        </>
    )
})

const OrganisationTimesheetsPage = observer(() => {
    const { orgId } = useParams()
    const store = useStore()
    const { organisationService } = useServices()

    let org = store.organisations.getOrCreate(orgId)

    useEffect(() => {
        organisationService.clients.list(orgId).then(list => {
            org.clients.load(list)
        })
    }, [])

    if (org.clients.loading) {
        return <LoadingComponent />
    }

    return (
        <div>
            <Calendar clients={org.clients.list} />
        </div>
    )
})

export default OrganisationTimesheetsPage
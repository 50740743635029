import React from 'react';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import Env from "../../utils/Env";
import {useNavigate} from "react-router";

const RedirectToPreviousUrl = ({children}) => {
    const auth0 = useAuth0()

    React.useEffect(() => {
        if (!auth0.isAuthenticated) {
            if (!auth0.isLoading) {
                localStorage.setItem("redirectTo", window.location.pathname+window.location.search)
            }
        }
    })

    return children
}

const NowAgentAuth0Provider = ({children}) => {
    const history = useNavigate()

    const onRedirectCallback = () => {
        if (localStorage.getItem("redirectTo")) {
            let redirect = localStorage.getItem("redirectTo")
            localStorage.removeItem("redirectTo")
            history(redirect)
        }
    }

    return (
        <Auth0Provider
            domain={Env("REACT_APP_AUTH0_DOMAIN")}
            clientId={Env("REACT_APP_AUTH0_CLIENT_ID")}
            audience={Env("REACT_APP_AUTH0_AUDIENCE")}
            scope={Env("REACT_APP_AUTH0_SCOPES")}
            redirectUri={window.location.origin}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            <RedirectToPreviousUrl>
                {children}
            </RedirectToPreviousUrl>
        </Auth0Provider>
    );
};

export default NowAgentAuth0Provider;
import {useForm} from "react-hook-form";
import {Button, Card, Col, Row} from "react-bootstrap";
import {useServices} from "../../providers/Services";
import {useState} from "react";
import {useStore} from "../../store/RootStore";


const EditFinancas = ({current = {}}) => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { personalService } = useServices()
    const [saving, setSaving] = useState(false)
    const store = useStore()

    const formControlClass = (field) => {
        if (field) {
            return "form-control is-invalid"
        } else {
            return "form-control"
        }
    }

    const onSubmit = data => {
        setSaving(true)
        personalService.financas.save(data)
            .then(() => {
                store.personal.financas.load(data)
                setSaving(false)
            })
            .catch(() => { setSaving(false) })
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label className="form-label">NIF</label>
                        <input className={formControlClass(errors.nif)} placeholder="Enter NIF"
                               {...register("nif", {
                                   required: "NIF required",
                                   value: current.nif || ""
                               })}
                        />
                    </div>
                    <div className="form-group" style={{marginTop: "15px"}}>
                        <label className="form-label">Password</label>
                        <input type="password" className={formControlClass(errors.password)} placeholder="Enter password"
                                  {...register("password", {
                                      required: "Password required",
                                  })} />
                    </div>
                </div>
            </div>
            <Button type="submit" className="btn btn-primary" style={{width: "100%", marginTop: "15px"}} disabled={saving}>Save</Button>
        </form>
    )
}

export default EditFinancas
import {action, makeObservable, observable} from "mobx";

class OrganisationInvites {
    list = []
    loading = true

    constructor() {
        makeObservable(this, {
            list: observable,
            loading: observable,
            load: action,
        })
    }

    load (list) {
        this.list.replace(list)
        this.loading = false
    }

    add (invite) {
        if (this.list.filter(it => it.email === invite.email).length === 0) {
            this.list.push(invite)
        }
    }
}

export default OrganisationInvites
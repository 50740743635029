import React from "react";
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate, useParams} from "react-router";


const TopBar = () => {
    const {logout} = useAuth0();
    const params = useParams();
    const history = useNavigate();
    const location = useLocation();

    return (
        <Navbar bg="light" expand="lg" style={{marginBottom: "20px"}}>
            <Container>
                <Navbar.Brand onClick={() => { if (location.pathname !== "/") history("/") }} style={{cursor: "pointer"}}>NowAgent</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse>
                    <Nav className="nav">
                    <NavDropdown title="Personal">
                        <NavDropdown.Item onClick={() => history(`/personal/bank`)}>Bank</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history(`/personal/at`)}>AT</NavDropdown.Item>
                    </NavDropdown>
                    {params.orgId &&
                        <NavDropdown title="Organisation">
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/bank`)}>Bank</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/spending`)}>Spending</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/timesheets`)}>Timesheets</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/clients`)}>Clients</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/reports`)}>Reports</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history(`/org/${params.orgId}/invite`)}>Invite</NavDropdown.Item>
                        </NavDropdown>
                    }
                    </Nav>
                </Navbar.Collapse>
                <Nav className="nav">
                    <NavDropdown title="User" id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Container>
        </Navbar>
    )
};

export default TopBar;

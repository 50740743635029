import React from "react";
import ListOrganisations from "../component/organisation/list/ListOrganisations";
import {Row} from "react-bootstrap";
import ListInvites from "../component/invite/ListInvites";

const DashboardPage = () => {
    return (
        <>
            <Row>
                <ListInvites />
            </Row>
            <Row>
                <ListOrganisations/>
            </Row>
        </>
    )
}

export default DashboardPage;
import {action, makeObservable, observable} from "mobx";

class Iva {
    loading = true
    list = []

    constructor() {
        makeObservable(this, {
            loading: observable,
            list: observable,
            load: action
        })
    }

    load (list) {
        this.list.replace(list)
        this.loading = false
    }
}

class Recibo {
    loading = true
    list = []

    constructor() {
        makeObservable(this, {
            loading: observable,
            list: observable,
            load: action
        })
    }

    load (list) {
        this.list.replace(list)
        this.loading = false
    }
}

class Irs {
    recibo = new Recibo()

    constructor() {
        makeObservable(this, {
            recibo: observable
        })
    }
}

class PersonalFinancas {
    nif = null
    exists = false
    loading = true
    iva = new Iva()
    irs = new Irs()

    constructor() {
        makeObservable(this, {
            iva: observable,
            irs: observable,
            loading: observable,
            exists: observable,
            load: action
        })
    }

    load (data) {
        if (data.error && data.error === "NOT_FOUND") {
            this.exists = false
        } else {
            this.exists = true
            this.nif = data.nif
        }
        this.loading = false
    }

}

export default PersonalFinancas
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

const GuestPage = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="text-center" style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            <div className="d-flex h-100 p-3 mx-auto flex-column">
                <main role="main" className="inner" style={{
                    padding: "0 1.5rem"
                }}>
                    <h1 className="cover-heading">Welcome to NowAgent</h1>
                    <p className="lead">You are currently a guest, please login into our system.</p>
                    <p className="lead">
                        <a className="btn btn-lg btn-outline-success" style={{
                            padding: "0.75rem 1.25rem",
                            fontWeight: 700
                        }} onClick={() =>
                            loginWithRedirect({
                                screen_hint: 'signup',
                            })
                        }>Login to NowAgent</a>
                    </p>
                </main>
            </div>
        </div>
    )

}

export default GuestPage;
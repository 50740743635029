import {useParams} from "react-router";
import {useStore} from "../../../../store/RootStore";
import React, {useState} from "react";
import {useServices} from "../../../../providers/Services";
import LoadingComponent from "../../../LoadingComponent";
import {Button} from "react-bootstrap";


const SpendingItemRow = ({data, onToggle}) => {
    const [checked, setChecked] = useState(false)
    return (
        <tr>
            <td><input type="checkbox" checked={checked} onChange={e => { onToggle(e.target.checked, data); setChecked(e.target.checked) }}/></td>
            <td>{data.description}</td>
            <td>{data.amount}</td>
        </tr>
    )
}

const SpendingJustification = ({transaction, onJustify}) => {
    const { orgId } = useParams()
    const store = useStore()
    const { organisationService } = useServices()

    const [spending, setSpending] = useState([])
    const [justifyDisabled, setJustifyDisabled] = useState(true)
    const [justifyPending, setJustifyPending] = useState(false)

    let org = store.organisations.getOrCreate(orgId)

    const addSpendingItem = (checked, data) => {
        let list = spending.slice().filter(it => it.id !== data.id)
        if (checked) {
            list.push(data)
        }
        setSpending(list)

        if (transaction.value.toFixed(2) === list.reduce((ac, it) => ac + parseFloat(it.amount), 0).toFixed(2)) {
            setJustifyDisabled(false)
        } else {
            setJustifyDisabled(true)
        }

    }

    const submitJustification = () => {
        setJustifyPending(true)
        organisationService.spendingJustification(orgId, transaction.id, spending.map(it => it.id))
            .then((justification) => {
                transaction.setJustification(justification)
                org.spending.removeAll(spending)
                onJustify()
                setJustifyPending(false)
            })
            .catch(e => {
                setJustifyPending(false)
            })
    }

    if (org.spending.loading) {
        return <LoadingComponent />
    }

    return (
        <>
            <table className="table">
                <tbody>
                {org.spending.list
                    .slice().sort((a, b) => { if (a.description > b.description) return -1; if (a.description < b.description) return 1; return 0; })
                    .map(it => <SpendingItemRow key={it.id} data={it} onToggle={addSpendingItem} />)}
                <tr>
                    <td colSpan="2">Selected: {spending.length}</td>
                    <td><b>Total: </b> {spending.reduce((ac, it) => ac + parseFloat(it.amount), 0).toFixed(2)}</td>
                </tr>
                </tbody>
            </table>

            <div>
                <Button style={{width: "100%"}} className="btn-block" disabled={justifyDisabled && !justifyPending} onClick={submitJustification}>Justify</Button>
            </div>
        </>
    )
}

export default SpendingJustification
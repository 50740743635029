import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useStore} from "../../../store/RootStore";
import BankList from "../../../component/bank/BankList";
import {observer} from "mobx-react";
import {useServices} from "../../../providers/Services";
import TransactionList from "../../../component/bank/transaction/TransactionList";
import LoadingComponent from "../../../component/LoadingComponent";
import JustificationModal from "../../../component/bank/transaction/justification/JustificationModal";

const PersonalBankPage = observer(() => {
    const history = useNavigate()
    const store = useStore()
    const { personalService } = useServices()

    const [currentTransaction, setCurrentTransaction] = useState(null)

    useEffect(() => {
        personalService.bank.list().then(list => store.personal.banks.load(list))
        personalService.transaction.list().then(list => store.personal.transactions.load(list))
    }, [])


    const syncTransactions = () => {
        personalService.transaction.sync().then(list => {
            store.personal.transactions.load(list)
        })
    }

    if (store.personal.banks.loading || store.personal.transactions.loading) {
        return <LoadingComponent />
    }

    return (
        <>
            <Row>
                <BankList banks={store.personal.banks.list.slice()} />
                <Button variant="outline-primary" onClick={() => history("/personal/bank/add")}>Add Bank</Button>
                <hr style={{marginTop: "20px"}} />
                <TransactionList
                    transactions={store.personal.transactions.list.slice()
                        .filter(t => store.personal.banks.list.some(b => b.bankId === t.bankId && b.checked))
                        .map(it => Object.assign(it, { bank: store.personal.banks.list.slice().filter(b => b.bankId === it.bankId)[0] }))}
                    onSync={syncTransactions}
                    onClick={(transaction) => setCurrentTransaction(transaction)}
                    showConfig={{out: false, stats: true}}
                />
            </Row>
            <JustificationModal transaction={currentTransaction} handleClose={() => setCurrentTransaction(null)} />
        </>
    )
})

export default PersonalBankPage;
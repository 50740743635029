import {action, makeObservable, observable} from "mobx";


class PersonalBank {
    constructor(data) {
        this.id = data.id
        this.bankName = data.bankName
        this.bankLogo = data.bankLogo
        this.bankId = data.bankId
        this.checked = true

        makeObservable(this, {
            checked: observable,
            toggle: action
        })
    }

    toggle () {
        this.checked = !this.checked
    }
}

export default PersonalBank
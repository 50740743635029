import {action, makeObservable, observable} from "mobx";

class OrganisationClientTimesheetsMonth {
    list = []
    loading = true

    constructor() {
        makeObservable(this, {
            list: observable,
            loading: observable,
            add: action,
            load: action
        })
    }

    add (item) {
        let newList = this.list.slice().filter(it => it.id !== item.id)
        newList.push(item)
        this.list.replace(newList)
    }

    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    get (day) {
        let filtered = this.list.filter(it => it.day === day)
        if (filtered.length > 0) {
            return filtered[0]
        }
        return undefined
    }
}

export default OrganisationClientTimesheetsMonth
import {Sanitize} from "../utils/StringUtils";
import {AlreadyExistsError} from "../errors/AlreadyExistsError";
import uuid from 'react-uuid'
import {validStatusCodes} from "./http/utils";

class OrganisationClientTimesheetsService {
    constructor(client) {
        this.client = client
    }

    list(orgId, clientId, year, month) {
        return this.client.execute(
            `/api/organisation/${orgId}/client/${clientId}/timesheets/list?year=${year}&month=${month}`
        ).then(validStatusCodes([200]))
            .then(res => res.json())
    }

    add (orgId, clientId, year, month, day, info) {
        return this.client.postJson(
            `/api/organisation/${orgId}/client/${clientId}/timesheets/add`,
            {
                year: year,
                month: month,
                day: day,
                data: info
            }
        ).then(validStatusCodes([200]))
            .then(res => res.json())
            .then(res => Object.assign(res, { day: new Date(res.day)}))
    }
}

class OrganisationClientsService {
    constructor(client) {
        this.client = client
        this.timesheets = new OrganisationClientTimesheetsService(client)
    }

    list(orgId) {
        return this.client.execute(`/api/organisation/${orgId}/client/list`)
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    add(orgId, data) {
        return this.client.postJson(`/api/organisation/${orgId}/client/add`, data)
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }
}

class OrganisationService {
    constructor(client) {
        this.httpClient = client;
        this.clients = new OrganisationClientsService(client)
    }

    addOrganisation(orgName, logoUrl) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute("/api/organisation/add", {
                scope: "write:organisation",
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    id: Sanitize(orgName),
                    name: orgName,
                    logoUrl: logoUrl
                })
            }).then(validStatusCodes([200, 409]))
                .then((res) => {
                    if (res.status === 409) {
                        throw new AlreadyExistsError("Already exists")
                    }
                    if (res.status !== 200) {
                        throw new Error("Invalid status code: " + res.status)
                    }
                    return res.json()
                })
                .then(res => {
                    resolve(res)
                })
                .catch(e => reject({
                    cause: e,
                    call: "this.client.execute(\"/api/organisation/add\""
                }))
        })
    }

    loadOrganisations() {
        return new Promise((resolve, reject) => {
            this.httpClient.execute("/api/organisation/list", {
                scope: "read:organisation"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res.list)
                })
                .catch(e => reject(e))
        })
    }

    getOrganisation(orgId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}`, {
                scope: "read:organisation"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => reject(e))
        })
    }

    listInvites(orgId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/invite/list`, {
                scope: "read:organisation"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }


    listBanks(orgId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/list`, {
                scope: "read:organisation"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    acceptBankAgreement(orgId, agreementId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/${agreementId}/accept`, {
                scope: "write:organisation",
                method: "PUT"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    listTransactions(orgId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/transaction/list`, {
                scope: "read:organisation",
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    accountDetails (orgId, accountId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/account/${accountId}/details`, {
                scope: "read:organisation",
                method: "GET"
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    syncTransactions(orgId) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/transaction/sync`, {
                scope: "write:organisation",
                method: "POST"
            })
                .then(res => {
                    res.json().then(data => {
                        if (res.status === 409) {
                            reject(data)
                        } else {
                            resolve(data)
                        }
                    }).catch(e => {
                        reject(e)
                    })
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    transactionJustifyBreakdown(id, breakdown, file) {
        return new Promise((resolve, reject) => {
            if (!file) {
                this.httpClient.execute(`/api/transaction/${id}/justify`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        type: "breakdown",
                        breakdown: breakdown,
                        timestamp: new Date()
                    })
                })
                    .then(validStatusCodes([200]))
                    .then(res => res.json())
                    .then(just => resolve(just))
                    .catch(e => {
                        reject(e)
                    })
            } else {
                let filePath = `justification/${id}`
                let data = new FormData()
                data.append("id", id)
                data.append("file", file)
                this.httpClient.execute(`/api/file/upload?path=${encodeURI(filePath)}`, {
                    scope: "write:organisation",
                    method: "POST",
                    body: data
                })
                    .then(validStatusCodes([200]))
                    .then(res => res.json())
                    .then(manifest => {
                        this.httpClient.execute(`/api/transaction/${id}/justify`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                type: "breakdown",
                                "file": filePath,
                                manifest: manifest,
                                breakdown: breakdown,
                                timestamp: new Date()
                            })
                        })
                            .then(res => res.json())
                            .then(just => resolve(just))
                            .catch(e => {
                                reject(e)
                            })
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    }

    transactionJustify(id, description, file) {
        return new Promise((resolve, reject) => {
            if (!file) {
                this.httpClient.execute(`/api/transaction/${id}/justify`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        description: description,
                        timestamp: new Date()
                    })
                })
                    .then(validStatusCodes([200]))
                    .then(res => res.json())
                    .then(just => resolve(just))
                    .catch(e => {
                        reject(e)
                    })
            } else {
                let filePath = `justification/${id}`
                let data = new FormData()
                data.append("id", id)
                data.append("file", file)
                this.httpClient.execute(`/api/file/upload?path=${encodeURI(filePath)}`, {
                    scope: "write:organisation",
                    method: "POST",
                    body: data
                })
                    .then(validStatusCodes([200]))
                    .then(res => res.json())
                    .then(manifest => {
                        this.httpClient.execute(`/api/transaction/${id}/justify`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                type: "simple",
                                "file": filePath,
                                manifest: manifest,
                                description: description,
                                timestamp: new Date()
                            })
                        })
                            .then(res => res.json())
                            .then(just => resolve(just))
                            .catch(e => {
                                reject(e)
                            })
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    }

    transactionJustificationDownload(id) {
        let filePath = `justification/${id}`
        return this.httpClient.execute(`/api/file/download?path=${encodeURI(filePath)}`, {
            scope: "read:organisation"
        }).then(validStatusCodes([200]))
    }

    sendInvitation(orgId, email) {
        return this.httpClient.execute(`/api/organisation/${orgId}/invite/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email
            }),
            scope: "read:organisation"
        }).then(validStatusCodes([200]))
    }

    listSpending(orgId) {
        return this.httpClient.execute(`/api/organisation/${orgId}/spending`, {
            scope: "read:organisation"
        })
            .then(validStatusCodes([200]))
            .then(res => res.json())
    }

    removeSpending(orgId, spending) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/file/delete?path=${encodeURI(spending.file)}`, {
                scope: "write:organisation",
                method: "DELETE"
            }).then(validStatusCodes([200])).then(() => {
                this.httpClient.execute(`/api/organisation/${orgId}/spending/${spending.id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(validStatusCodes([200]))
                    .then(() => resolve())
                    .catch(e => {
                        reject(e)
                    })
            }).catch(e => reject(e))
        })
    }

    downloadSpendingItem(id) {
        let filePath = `spending/${id}`
        return this.httpClient.execute(`/api/file/download?path=${encodeURI(filePath)}`, {
            scope: "read:organisation"
        }).then(validStatusCodes([200]))
    }

    addSpending(orgId, description, amount, file) {
        return new Promise((resolve, reject) => {
            let id = uuid();
            let filePath = `spending/${id}`
            let data = new FormData()
            data.append("file", file)
            this.httpClient.execute(`/api/file/upload?path=${encodeURI(filePath)}`, {
                scope: "write:organisation",
                method: "POST",
                body: data
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(manifest => {
                    this.httpClient.execute(`/api/organisation/${orgId}/spending/add`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            "id": id,
                            "file": filePath,
                            manifest: manifest,
                            amount: amount,
                            description: description,
                            timestamp: new Date()
                        })
                    }).then(validStatusCodes([200]))
                        .then(res => res.json())
                        .then(just => resolve(just))
                        .catch(e => {
                            reject(e)
                        })
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    spendingJustification(orgId, transactionId, spendingIdList) {
        return new Promise((resolve, reject) => {
            this.httpClient.execute(`/api/organisation/${orgId}/bank/transaction/${transactionId}/justify/spending`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    spending: spendingIdList
                })
            })
                .then(validStatusCodes([200]))
                .then(res => res.json())
                .then(transactionJustification => resolve(transactionJustification))
                .catch(e => {
                    reject(e)
                })
        })
    }
}

export default OrganisationService;
import React from 'react';
import ReactDOM from 'react-dom/client';
import {configure} from "mobx"
import {RootStoreProvider} from './store/RootStore'

import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import NowAgentAuth0Provider from "./component/auth/AuthProviderWithHistory";
import App from "./App";
import {ServicesProvider} from "./providers/Services";


configure({useProxies: "never"})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <NowAgentAuth0Provider>
            <RootStoreProvider>
                <ServicesProvider>
                    <App/>
                </ServicesProvider>
            </RootStoreProvider>
        </NowAgentAuth0Provider>
    </BrowserRouter>
);


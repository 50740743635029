import React from "react";
import {Container} from "react-bootstrap";
import EndOfYearReport from "../../../component/reports/EndOfYearReport";

const OrganisationReportsPage = () => {
  return (
      <Container>
        <EndOfYearReport />
      </Container>
  )
}

export default OrganisationReportsPage;

import {Col, Row} from "react-bootstrap";
import LoadingSpin from "react-loading-spin";
import React from "react";


const LoadingComponent = () => {
    return (
        <Row>
            <Col className="text-center" style={{marginTop: "40px"}}>
                <LoadingSpin />
            </Col>
        </Row>
    )
}

export default LoadingComponent
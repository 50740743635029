import {observer} from "mobx-react";
import {Alert, Badge, Button, Table} from "react-bootstrap";
import React from "react";

const TransactionListEmpty = ({onSync}) => {
    return (
        <Alert variant="info" style={{marginTop: "20px"}}>
            <Alert.Heading>No Transactions yet</Alert.Heading>
            <p>
                You don't have transactions yet, sync up your accounts
            </p>
            <hr/>
            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={() => onSync()}>
                    Synchronise Accounts
                </Button>
            </div>
        </Alert>
    )
}

const TransactionListTableRow = ({transaction, showConfig, onClick}) => {
    return (
        <>
            <tr className={transaction.justification ? "table-success" : "table-danger"}>
                <td>
                    {transaction.valueDate}
                </td>
                <td>{transaction.bank.bankName}</td>
                <td>{onClick && <a style={{cursor: "pointer"}} className="link-primary" onClick={() => onClick(transaction)}>{transaction.description}</a>}</td>
                <td>{!onClick && transaction.description}</td>
                <td>{transaction.in ? (
                    <Badge bg="success">{parseFloat(transaction.value).toFixed(2)}</Badge>) : ""}</td>
                {showConfig.out &&
                    <td>{!transaction.in ? (
                        <Badge bg="danger">{parseFloat(transaction.value).toFixed(2)}</Badge>) : ""}</td>}
            </tr>
        </>
    )
}

const TransactionStatsRow = ({transactions, showConfig}) => {
    let total = transactions.reduce((ac, it) => ac + (it.value*(it.in ? 1:-1)), 0).toFixed(2)
    let totalIn = transactions.reduce((ac, it) => {
        if (it.in) {
            return ac + it.value
        }
        return ac
    }, 0).toFixed(2)
    let totalOut = transactions.reduce((ac, it) => {
        if (!it.in) {
            return ac + it.value
        }
        return ac
    }, 0).toFixed(2)

    return (
        <tr className="table-info">
            <td colSpan={4}>
                <b>Total:</b> {transactions.length} | <b>Cash Flow: </b><Badge bg={total > 0 ? "success" : "danger"}>{total}</Badge>
            </td>
            <td><Badge bg="success">{totalIn}</Badge></td>
            {showConfig.out && <td><Badge bg="danger">{totalOut}</Badge></td>}
        </tr>
    )
}

const TransactionListTable = ({transactions, showConfig, onClick}) => {
    return (
        <Table>
            <thead>
            <tr>
                <th>Date</th>
                <th>Bank</th>
                <th>Description</th>
                {showConfig.out && <th>Amount In</th>}
                {showConfig.out && <th>Amount Out</th>}
                {!showConfig.out && <th>Amount</th>}
            </tr>
            </thead>
            <tbody>
            {transactions.map(it => <TransactionListTableRow key={it.id} onClick={onClick} transaction={it} showConfig={showConfig} />)}
            {showConfig.stats && <TransactionStatsRow transactions={transactions} showConfig={showConfig} />}
            </tbody>
        </Table>
    )
}

const TransactionList = observer(({transactions, onSync, onClick, showConfig}) => {
    if (transactions.length === 0) {
        return <TransactionListEmpty onSync={onSync} />
    }

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={() => onSync()}>
                    Synchronise Accounts
                </Button>
            </div>

            <TransactionListTable onClick={onClick} transactions={transactions.filter(it => it.in || showConfig.out)} showConfig={showConfig} />
        </div>
    )
})

export default TransactionList
import React, {useState} from "react";
import {useServices} from "../../../providers/Services";
import {useParams} from "react-router";
import {useStore} from "../../../store/RootStore";
import {observer} from "mobx-react";
import LoadingSpin from "react-loading-spin";
import fileDownload from "js-file-download";


const AddSpending = () => {
    const [description, setDescription] = useState("")
    const [amount, setAmount] = useState("")
    const [file, setFile] = useState(null)
    const [fileEl, setFileEl] = useState(null)

    const [descriptionInvalid, setDescriptionInvalid] = useState(false)
    const [amountInvalid, setAmountInvalid] = useState(false)
    const [fileInvalid, setFileInvalid] = useState(false)
    const [adding, setAdding] = useState(false)

    const { orgId } = useParams()
    const { organisationService } = useServices()
    const store = useStore()

    let org = store.organisations.getOrCreate(orgId)

    const validateForm = () => {
        return [
            () => {
                if (description) {
                    setDescriptionInvalid(false)
                    return true
                } else {
                    setDescriptionInvalid(true)
                    return false
                }
            },
            () => {
                if (amount) {
                    if (parseFloat(amount) > 0) {
                        setAmountInvalid(false)
                        return true
                    }
                }
                setAmountInvalid(true)
                return false
            },
            () => {
                if (file) {
                    setFileInvalid(false)
                    return true
                } else {
                    setFileInvalid(true)
                    return false
                }
            }
        ]
            .map(it => it())
            .every(it => it)
    }

    const resetForm = () => {
        setDescription("")
        setAmount("")
        if (fileEl) {
            fileEl.target.value = null
        }
    }

    const submit = () => {
        setAdding(true)
        if (validateForm()) {
            organisationService.addSpending(orgId, description, amount, file)
                .then(data => {
                    org.spending.add(data)
                    setAdding(false)
                    resetForm()
                })
                .catch(e => {
                    setAdding(false)
                })
        } else {
            setAdding(false)
        }
    }

    return (
        <tr>
            <td>
                <div className="form-group">
                    <input type="text" className={descriptionInvalid ? "form-control is-invalid" : "form-control"}
                           placeholder="Description" required value={description}
                           onChange={e => setDescription(e.target.value)}/>
                    <div className="invalid-feedback">
                        Please provide a description.
                    </div>
                </div>
            </td>
            <td>
                <div className="form-group">
                    <input type="number" className={amountInvalid ? "form-control is-invalid" : "form-control"}
                           placeholder="Amount in EUR" pattern="[0-9]+(\.[0-9]+)" required value={amount}
                           onChange={e => setAmount(e.target.value)}/>
                    <div className="invalid-feedback">
                        Please provide a positive amount.
                    </div>
                </div>
            </td>
            <td>
                <div className="form-group">
                    <input type="file" className={fileInvalid ? "form-control is-invalid" : "form-control"}
                           onChange={e => {setFile(e.target.files[0]); setFileEl(e)}}/>
                    <div className="invalid-feedback">
                        Please provide an attachment.
                    </div>
                </div>
            </td>
            <td>
                <div className="form-group">
                    <button disabled={adding} className="form-control btn btn-primary" onClick={() => submit()}>Add</button>
                </div>
            </td>
        </tr>
    )
}

const ListSpending = observer(() => {
    const { orgId } = useParams()
    const { organisationService } = useServices()
    const store = useStore()

    let org = store.organisations.getOrCreate(orgId)

    React.useEffect(() => {
        organisationService.listSpending(orgId)
            .then(list => org.spending.load(list))
    }, [])

    if (org.spending.loading) {
        return (
            <tr>
                <td colSpan="4" className="text-center">
                    <LoadingSpin size="20px" />
                </td>
            </tr>
        )
    }

    const deleteEntry = (data) => {
        organisationService.removeSpending(orgId, data).then(() => {
            org.spending.remove(data)
        })
    }

    const handleDownload = (spendingItem) => {
        organisationService.downloadSpendingItem(spendingItem.id)
            .then(res => res.blob())
            .then(res => {
                fileDownload(res, spendingItem.manifest.name, spendingItem.manifest.mimeType)
            })
    }

    return (
        <>
            {org.spending.list.slice().sort((a, b) => {
                if (a.description > b.description) return 1;
                else if (a.description < b.description) return -1;
                else return 0;
            }).map(it => <tr key={it.id}>
                <td>{it.description}</td>
                <td>{it.amount}</td>
                <td><a className="link-primary" style={{cursor: "pointer"}} onClick={() => handleDownload(it)}>Download</a></td>
                <td><small><button className="btn btn-danger btn-sm" onClick={() => deleteEntry(it)}>Drop</button></small></td>
            </tr>)}
            <tr className="table-info">
                <td><b>Total</b></td>
                <td>{org.spending.list.reduce((ac, n) => ac + parseFloat(n.amount), 0).toFixed(2)}</td>
                <td></td>
                <td></td>
            </tr>
        </>
    )
})

const OrganisationSpendingPage = () => {
    return (
        <div>
            <table className="table">
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Attachment</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                <ListSpending/>
                <AddSpending/>
                </tbody>
            </table>
        </div>
    )
}

export default OrganisationSpendingPage
import {useParams} from "react-router";
import {useStore} from "../../../store/RootStore";
import {useEffect, useState} from "react";
import {useServices} from "../../../providers/Services";
import LoadingComponent from "../../../component/LoadingComponent";
import {observer} from "mobx-react";
import {Button, Card, Col, Collapse, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";

const AddClient = ({onAddClient}) => {
    const [adding, setAdding] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { organisationService } = useServices()
    const { orgId } = useParams()

    const onSubmit = data => {
        setAdding(true)
        organisationService.clients.add(orgId, data)
            .then(client => {
                onAddClient(client)
                setAdding(false)
            })
            .catch(e => {
                setAdding(false)
            })
    }

    const formControlClass = (field) => {
        if (field) {
            return "form-control is-invalid"
        } else {
            return "form-control"
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label className="form-label">Name</label>
                        <input className={formControlClass(errors.name)} placeholder="Enter name"
                               {...register("name", {required: "Name required"})}
                        />
                    </div>
                    <div className="form-group" style={{marginTop: "15px"}}>
                        <label className="form-label">Address</label>
                        <textarea rows={5} className={formControlClass(errors.address)} placeholder="Enter address"
                                  {...register("address", {required: "Address required"})} />
                    </div>
                    <Row style={{marginTop: "15px"}}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Bank</Card.Title>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">IBAN</label>
                                                <input className={formControlClass(errors.bankIban)} placeholder="Enter IBAN"
                                                       {...register("bankIban", {required: "IBAN required"})} />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">Swift Code</label>
                                                <input className={formControlClass(errors.bankSwift)} placeholder="Enter swift code"
                                                       {...register("bankSwift", {required: "SWIFT required"})} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Rate</Card.Title>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">Rate</label>
                                                <input type="number" min="0" step="0.01" className={formControlClass(errors.bankIban)} placeholder="Enter Rate"
                                                       {...register("rate", {required: "Rate required"})} />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">Currency</label>
                                                <input className={formControlClass(errors.bankSwift)} placeholder="Enter currency"
                                                       {...register("currency", {required: "Currency required"})} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Invoice Reference</Card.Title>
                                    <Row>
                                        <Col>
                                            <div className="form-group" style={{marginTop: "15px"}}>
                                                <label className="form-label">From Details</label>
                                                <textarea rows={5} className={formControlClass(errors.from)} placeholder="Enter from details"
                                                          {...register("from", {required: "From details required"})} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Button type="submit" className="btn btn-primary" style={{width: "100%", marginTop: "15px"}} disabled={adding}>Create Client</Button>
        </form>
    )
}
const ClientItem = observer(({client}) => {
    return (
        <Card style={{marginBottom: "10px"}}>
            <Card.Body>
                <Card.Title className="link-primary" style={{cursor: "pointer"}}>{client.name}</Card.Title>
                <Card.Body>
                    <Row>
                        <Col className="col-sm">
                            <p><b>Address</b></p>
                        </Col>
                        <Col>
                            {client.address.split("\n").map(it => <p key={it} style={{marginBottom: "5px"}}>{it}</p>)}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="col-sm">
                            <p><b>Bank IBAN</b></p>
                            <p><b>Bank Swift</b></p>
                        </Col>
                        <Col>
                            <p>{client.bankIban}</p>
                            <p>{client.bankSwift}</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="col-sm">
                            <p><b>From Details</b></p>
                        </Col>
                        <Col>
                            {client.from.split("\n").map(it => <p key={it} style={{marginBottom: "5px"}}>{it}</p>)}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="col-sm">
                            <p><b>Rate</b></p>
                        </Col>
                        <Col>
                            {client.rate} {client.currency}
                        </Col>
                    </Row>
                </Card.Body>
            </Card.Body>
        </Card>
    )
})

const OrganisationClientsPage = observer(() => {
    const { orgId } = useParams()
    const store = useStore()
    const { organisationService } = useServices()

    let org = store.organisations.getOrCreate(orgId)

    useEffect(() => {
        organisationService.clients.list(orgId).then(list => {
            org.clients.load(list)
        })
    }, [])


    if (org.clients.loading) {
        return <LoadingComponent />
    }

    if (org.clients.list.length === 0) {
        return (
            <AddClient onAddClient={client => org.clients.add(client)} />
        )
    }

    return (
        <div>
            {org.clients.list.map(it => <ClientItem key={it.id} client={it} /> )}
            <hr />

            <AddClient onAddClient={client => org.clients.add(client)} />
        </div>
    )
})

export default OrganisationClientsPage
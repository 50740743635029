import React, {useState} from "react";
import {observer} from "mobx-react";
import {Container} from "react-bootstrap";
import {useStore} from "../../../store/RootStore";
import LoadingComponent from "../../../component/LoadingComponent";
import BankAddList from "../../../component/bank/add/BankAddList";
import {useParams} from "react-router";
import {useServices} from "../../../providers/Services";
import RequestingLinkComponent from "../../../component/bank/RequestingLinkComponent";


const OrganisationBankAddPage = (observer(() => {
    let store = useStore()
    let { orgId } = useParams()
    let [requestingLink, setRequestingLink] = useState(false)
    let { bankService } = useServices()


    if (requestingLink) {
        return (
            <RequestingLinkComponent />
        )
    }

    return (
        <Container>
            <BankAddList banks={store.banks.list} onClick={(bankId) => {
                setRequestingLink(true)
                bankService.requestLink(orgId, bankId).then(link => {
                    window.location.href = link
                })
            }} />
        </Container>
    )
}))

export default OrganisationBankAddPage;
import './App.css';
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import DashboardPage from "./pages/DashboardPage";
import {Route, Routes} from "react-router-dom";
import GuestPage from "./pages/GuestPage";
import LoadingPage from "./pages/LoadingPage";
import NotFoundPage from "./pages/NotFoundPage";
import AddOrganisationPage from "./pages/organisation/AddOrganisationPage";
import OrganisationBankAddPage from "./pages/organisation/bank/OrganisationBankAddPage";
import OrganisationBankPage from "./pages/organisation/bank/OrganisationBankPage";
import OrganisationBankAddCallbackPage from "./pages/organisation/bank/OrganisationBankAddCallbackPage";
import Layout from "./pages/Layout";
import OrganisationInvitePage from "./pages/organisation/invite/OrganisationInvitePage";
import OrganisationSpendingPage from "./pages/organisation/spending/OrganisationSpendingPage";
import OrganisationTimesheetsPage from "./pages/organisation/timesheets/OrganisationTimesheetsPage";
import OrganisationClientsPage from "./pages/organisation/clients/OrganisationClientsPage";
import PersonalBankPage from "./pages/personal/bank/PersonalBankPage";
import PersonalBankAddPage from "./pages/personal/bank/PersonalBankAddPage";
import PersonalBankAddCallbackPage from "./pages/personal/bank/PersonalBankAddCallbackPage";
import AutoridadeTributariaPage from "./pages/personal/at/AutoridadeTributariaPage";
import OrganisationBankAccountSuspendedPage from "./pages/organisation/bank/OrganisationBankAccountSuspendedPage";
import OrganisationBankAccountEuaExpiredPage
  from "./pages/organisation/bank/OrganisationBankAccountEuaExpiredPage";
import OrganisationReportsPage
  from "./pages/organisation/reports/OrganisationReportsPage";
import DownloadPage from "./pages/DownloadPage";

const App = () => {
    const {isAuthenticated, isLoading} = useAuth0()

    if (!isAuthenticated) {
        if (isLoading) {
            return <LoadingPage />;
        } else {
          return (
              <Routes>
                <Route path="/download" element={<DownloadPage/>}/>
                <Route path="*" element={<GuestPage />} />
              </Routes>
          )
        }
    }


    return (
        <Routes>
            <Route path="/" element={<Layout><DashboardPage/></Layout>}/>
            <Route path="/download" element={<Layout><DownloadPage/></Layout>}/>
            <Route path="/personal/at" element={<Layout><AutoridadeTributariaPage/></Layout>}/>
            <Route path="/personal/bank" element={<Layout><PersonalBankPage/></Layout>}/>
            <Route path="/personal/bank/add" element={<Layout><PersonalBankAddPage/></Layout>}/>
            <Route path="/personal/bank/callback" element={<Layout><PersonalBankAddCallbackPage/></Layout>}/>
            <Route path="/org/add" element={<Layout><AddOrganisationPage/></Layout>}/>
            <Route path="/org/:orgId" element={<Layout><OrganisationBankPage/></Layout>}/>
            <Route path="/org/:orgId/invite" element={<Layout><OrganisationInvitePage/></Layout>}/>
            <Route path="/org/:orgId/spending" element={<Layout><OrganisationSpendingPage/></Layout>}/>
            <Route path="/org/:orgId/timesheets" element={<Layout><OrganisationTimesheetsPage/></Layout>}/>
            <Route path="/org/:orgId/reports" element={<Layout><OrganisationReportsPage/></Layout>}/>
            <Route path="/org/:orgId/clients" element={<Layout><OrganisationClientsPage/></Layout>}/>
            <Route path="/org/:orgId/bank" element={<Layout><OrganisationBankPage/></Layout>}/>
            <Route path="/org/:orgId/bank/add" element={<Layout><OrganisationBankAddPage /></Layout>} />
            <Route path="/org/:orgId/bank/callback" element={<Layout><OrganisationBankAddCallbackPage /></Layout>} />
            <Route path="/org/:orgId/bank/account/:accountId/suspended" element={<Layout><OrganisationBankAccountSuspendedPage /></Layout>} />
            <Route path="/org/:orgId/bank/account/:accountId/eua-expired" element={<Layout><OrganisationBankAccountEuaExpiredPage /></Layout>} />
            <Route path="/org/:orgId/bank/:bankId" element={<Layout><OrganisationBankAddPage /></Layout>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    )
};

export default App;

import React from "react";
import {Container} from "react-bootstrap";
import AddOrganisation from "../../component/organisation/list/AddOrganisation";
import {useNavigate} from "react-router";

const AddOrganisationPage = () => {
    const history = useNavigate()

    return (
        <Container>
            <AddOrganisation onCreate={(org) => { history("/org/" + org.id) }}/>
        </Container>
    )
}

export default AddOrganisationPage;
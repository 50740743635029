import {action, makeObservable, observable} from "mobx";
import OrganisationClient from "./OrganisationClient";


class OrganisationClients {
    loading = true
    list = []

    constructor() {
        makeObservable(this, {
            loading: observable,
            list: observable,
            add: action,
            load: action
        })
    }

    add (item) {
        if (this.list.some(it => it.id === item.id)) {

        } else {
            this.list.push(new OrganisationClient(item))
        }
    }

    load (list) {
        list.forEach(it => this.add(it))
        this.loading = false
    }

    get (id) {
        return this.list.filter(it => it.id === id)[0]
    }
}

export default OrganisationClients